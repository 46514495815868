import { createRouter, createWebHistory } from 'vue-router';
import DashBoard from './views/DashBoard.vue';
import LoginForm from './components/LoginForm';
import ListBike from './components/ListBike';
import SearchTable from './components/SearchTable';
import BazaBike from './components/BazaBike';
import AdminProfil from './components/AdminProfil';
import AllGid from './components/AllGid';
import AllLudi from './components/AllLudi.vue'
import ZakazAll from './components/ZakazAll.vue';
import Basket from './components/BasketOrder.vue';
// router.js
import store from './store'; // импорт хранилища

const routes = [
  { path: '/', component: LoginForm },
  { path: '/login', component: LoginForm}, 
  { path: '/all', component: AllLudi , meta: { requiresAuth: true }  }, 
  { path: '/allzakaz', component: ZakazAll , meta: { requiresAuth: true } }, 
  { path: '/basket', component: Basket , meta: { requiresAuth: true } }, 
  { path: '/dashboard', component: DashBoard, meta: { requiresAuth: true } },
  { path: '/participants', component: SearchTable, meta: { requiresAuth: true } },
  { path: '/equipment', component: ListBike, meta: { requiresAuth: true } },
  { path: '/baza', component: BazaBike, meta: { requiresAuth: true } },
  { path: '/profil', component: AdminProfil, meta: { requiresAuth: true } },
  { path: '/gid', component: AllGid, meta: { requiresAuth: true } },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }

  // Используем pushState() для обновления URL при переходе
  window.history.pushState(null, null, to.path);
});

export default router;
