<template>
    <div>
        <!-- Ваш контент модального окна -->
        <div v-if="isModalVisible" class="modal-overlay">
            <div class="modal">
                <!-- Крестик для закрытия модального окна -->
                <span class="modal-close" @click="hideModal">
                    <i class="fas fa-times"></i>
                </span>
                <h3>{{ selectedTourName }}</h3>
                <div class="tour-menu bike">
                    <h2>Список арендной техники</h2>
                    <ul>
                        <li v-for="(moto, index) in motos" :key="index" @click="handleCheckboxClick(index)">
                            <label :for="'motoCheckbox_' + index">
                                <input type="checkbox" :id="'motoCheckbox_' + index" v-model="selectedMotos"
                                    :value="moto" @change="() => handleCheckboxClick(index)" />
                                {{ moto.name }}
                            </label>
                        </li>
                    </ul>
                </div>
                <div>
                    <button @click="hideModal">Закрыть</button>
                </div>
                <!-- Ваш контент модального окна -->

            </div>
        </div>
        <!-- <ListBike /> -->

        <!-- вывод таблиц список туров отображение выбранного тура -->
        <div class="all">
            <!-- Список туров слева -->
            <div class="tour-menu">
                <h2>Список туров</h2>
                <!-- Фильтр по названию тура -->
                <div style="padding-bottom: 10px;">
                    <label for="searchName">Название тура:</label>
                    <input type="text" v-model="searchName" placeholder="введите название тура"
                        @input="handleInputTours" @click="resetBiketoor" />
                </div>
                <!-- Фильтр по году тура -->
                <div>
                    <label for="searchDescription">Год тура:</label>
                    <input type="text" v-model="searchDescription" placeholder="введите год" @input="fetchTours"
                        @click="resetBiketoor" />
                </div>
                <!-- Список туров -->
                <ul>
                    <li v-for="(tour, index) in tours" :key="tour._id">
                        <div class="myShow" v-if="!tour.isEditing">
                            <span @click="showTourDates(index)"> {{ tour.name ? tour.name : 'Нет названия' }} {{
            tour.description ? tour.description : 'Нет описания' }}
                                <span
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <button v-if="selectedTour === index" style="margin-left: 10px;"
                                        @click.prevent="editTour(index)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button v-if="selectedTour === index" @click.prevent="deleteTour(index)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                    <button v-if="selectedTour === index" @click="showModal(index)">
                                        <i class="fas fa-plus"></i>Добавить мотоцикл
                                    </button>
                                </span>
                            </span>


                        </div>
                        <!-- Форма для редактирования тура -->
                        <div v-else>
                            <input type="text" v-model="tours[index].name" @click.stop>
                            <input type="text" v-model="tours[index].description" @click.stop>
                            <button @click.prevent="saveTour(index)">Сохранить тур</button>
                            <button @click.prevent="deleteTour(index)">Удалить</button>
                        </div>
                    </li>

                </ul>
                <!-- Навигация по страницам -->
                <button @click="prevPage" :disabled="currentPage === 1">
                    Назад
                </button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button @click="nextPage" :disabled="currentPage === totalPages">
                    Далее
                </button>
                <br>
                <!-- Добавление нового тура для администратора -->
                <button
                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                    @click="addNewTour"><i class="fas fa-plus"></i>Добавить
                    тур</button>
            </div>
            <!-- Отображение даты выбранного тура -->
            <div class="tour-dates" v-if="selectedTour !== null">
                <h2>Выбранный тур: {{ tours[selectedTour].name }}</h2>
                <p>Даты тура:</p>

                <ul>
                    <li v-for="(date, dateIndex) in tours[selectedTour].dates" :key="dateIndex">
                        <div v-if="!date.isEditing">
                            <span class="padding_palace" @click="generateMessage(date)">
                                {{ formatDate(date.tour_date) }}- {{ formatDate(date.tour_date_end) }}
                                Количество мест: {{ date.totalSeats }}

                            </span>
                            <button
                                v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                @click="editTourDate(dateIndex)"> <i class="fas fa-edit"></i> </button>
                            <button
                                v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                @click="deleteDate(dateIndex)"> <i class="fas fa-trash"></i> </button>
                        </div>
                        <div v-else class="editData">
                            <label for=""> начало тура</label>

                            <!-- <input type="date" v-model="editedDate.start" name="" id=""  :placeholder="date.start"> -->
                            <Datepicker v-model="editedDate.start" locale="ru" :placeholder="date.start"
                                :format="format" style="width: 200px;" />
                            <label for="">Конец тура</label>
                            <!-- <input type="date" v-model="editedDate.end" name="" id=""  :placeholder="date.end"> -->
                            <Datepicker v-model="editedDate.end" locale="ru" :placeholder="date.end" :format="format"
                                style="width: 200px;" />
                            <label for="">Свободные места</label>
                            <input type="number"
                                v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                v-model="editedDate.totalSeats" :placeholder="date.totalSeats" />
                            <button
                                v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                @click="saveEdit(dateIndex)"><i class="fas fa-save"></i> </button>
                            <button
                                v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                @click="cancelEditTourDate(dateIndex)"> <i class="fas fa-times"></i> </button>
                        </div>
                    </li>
                </ul>
                <div>

                    <button
                        v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                        @click="addNewTourDate"><i class="fas fa-plus"></i>Добавить дату</button>
                    <!-- <button @click="cancelEditTourDate(index)">Cancel</button> -->
                </div>
            </div>
        </div>
        <!-- вывод таблицы со списком участников к конкретной дате тура -->
        <div class="message-box">
            <!-- Блок, который будет отображен только если переменная showTable равна true -->
            <div v-if="showTable == true">
                <!-- Уведомление о загрузке файла -->
                <div class="tour-menu bike" style="padding-bottom: 10px;">
                    <div v-if="fileUploadedMessage1">
                        <p>{{ fileUploadedMessage1 }}</p>
                    </div>

                    <h2>Список гидов </h2>
                    <ul>
                        <!-- Итерация по списку гидов для их отображения -->
                        <li v-for="(contract, index) in contracts" :key="index" @click="showEdidContract(index)"
                            :class="{ 'editing-guide': editingGuideIndex === index }">
                            <!-- Режим отображения информации о гиде -->
                            <div v-if="!contract.isEditing">
                                <!-- Отображение имени гида и ссылки на его контракт -->
                                {{ contract.guide_name }} <a target="_blank"
                                    :href="`https://api.motoproject18.ru:3000/uploads_gid/${contract.name}`">
                                    {{ contract.name }}</a>
                                <!-- Кнопки действий только для администратора -->
                                <span
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <button v-if="editingGuideIndex === index"
                                        @click="editContract(index)">Редактировать</button>
                                    <button v-if="editingGuideIndex === index"
                                        @click="deleteContract(index)">Удалить</button>
                                </span>

                                <!-- Форма для загрузки файла -->
                                <div v-if="editingGuideIndex === index">
                                    <!-- Поля для загрузки файла и отображения уведомлений -->
                                    <input
                                        v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                        type="file" accept="application/pdf"
                                        @change="handleFileUploadContract('tour_contract', $event, index)" />
                                    <!-- Уведомление о загрузке файла -->
                                    <div v-if="loading1">Uploading...
                                        <div v-if="downloadLink">
                                            <p>файл загружен:</p>
                                            <a target="_blank" :href="downloadLink" :download="downloadLink"
                                                v-if="downloadLink">{{
            fileName }}</a>
                                        </div>
                                    </div>

                                    <!-- Режим редактирования информации о гиде -->
                                    <div v-if="fileUploadedMessage1">
                                        <p>Файл успешно загружен для статуса {{ fileName }} </p>

                                    </div>

                                </div>

                            </div>

                            <div v-else>
                                <!-- Форма для выбора гида и кнопок сохранения и удаления -->
                                <div class="guide-select-container">
                                    <!-- Выпадающий список для выбора гида -->
                                    <label for="guideSelect">Выберите гида:</label>
                                    <!-- Опции списка гидов -->
                                    <select v-model="contracts[index].guide_id" @change="handleGuideChange(index)">
                                        <option disabled>Выберите гида</option>
                                        <option v-for="(guide, guideIndex) in guideOptions" :key="guideIndex"
                                            :value="guide.guide_id"
                                            :selected="guide.guide_id === contracts[index].guide_id">
                                            {{ guide.guide_name }}
                                        </option>
                                    </select>
                                    <!-- Кнопки сохранения и удаления -->
                                    <button @click="saveContract(index)">Сохранить</button>
                                    <button @click="deleteContract(index)">Удалить</button>
                                </div>

                            </div>
                        </li>
                    </ul>
                    <!-- Кнопка для добавления нового гида -->
                    <button @click="toggleAddGuideForm"
                        v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                        style="margin-bottom: 10px;"> <i class="fas fa-plus"></i> Добавить
                        гида</button>
                    <!-- Форма для добавления нового гида -->
                    <div v-if="showAddGuideForm">
                        <label for="guideSelect">Выберите гида:</label>
                        <!-- Выпадающий список для выбора гида -->
                        <select id="guideSelect" v-model="selectedGuide" @change="handleGuideChange">
                            <!-- Опции списка гидов -->
                            <option disabled>Выберите гида</option>
                            <option v-for="(guide, index) in guideOptions" :key="index" :value="guide.guide_id">
                                {{ guide.guide_name }}
                            </option>
                        </select>
                        <!-- Кнопки сохранения нового гида -->
                        <!-- Показываем кнопку "Добавить договор" только если выбран гид -->
                        <div
                            v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                            <button v-if="editingContract === null" @click="addNewContract()"><i
                                    class="fas fa-save"></i>
                                Сохранить гида</button>
                            <button v-else @click="saveContractChanges">Сохранить изменения</button>
                        </div>

                    </div>
                </div>




                <!-- Отображение выбранного тура и свободных мотоциклов -->
                <h2> {{ selectedTourMessage }}</h2>
                <p>свободные мотоциклы</p>
                <!-- отображение свободных мотоциклов в туре -->
                <ul class="freeBikes">
                    <li v-for="result in uniqueBikesInResponse2.value" :key="result.id">
                        {{ result.equipment_name }} - {{ result.price }}р /
                    </li>
                </ul>
                <!-- Отображение информации о датах выбранного тура -->
                <div>
                    <p v-if="userDetails[0]?.tourDate"><b> {{ formatDate(userDetails[0]?.tourDate?.tour_date) }} - {{
            formatDate(userDetails[0]?.tourDate?.tour_date_end) }}</b></p>

                </div>

                <div>
                    <h2> {{ selectedTourMessage1 }}</h2>
                    <!-- Таблица с информацией о турах и мотоциклах -->
                    <table>

                        <thead>
                            <tr>
                                <th v-for="(header, index) in tableHeaders" :key="index"
                                    :style="{ backgroundColor: '#f2f2f2' }">
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(item, index) in userDetails" :key="index">
                            <tr>
                                <td>{{ index + 1 }}</td>
                                <!-- вывод фио -->
                                <td>
                                    <span>{{ item.user?.fio }}</span>
                                    <!-- <input v-else v-model="editedValues.user.fio" /> -->
                                </td>
                                <!-- редактирование -->
                                <td
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <span v-if="editableIndex !== index">{{ item.avance_tour }}</span>
                                    <input v-else v-model="editedValues.avance_tour" />
                                </td>
                                <!-- скрытие кнопки для гида -->
                                <td
                                    v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))">
                                    <span>{{ item.avance_tour }}</span>

                                </td>
                                <!-- показ кнопки редактирования для админа -->
                                <td
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <span v-if="editableIndex !== index">{{ item.schet }}</span>
                                    <input v-else v-model="editedValues.schet" />
                                </td>
                                <!-- скрытие кнопки для гида -->
                                <td
                                    v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))">
                                    <span>{{ item.schet }}</span>

                                </td>

                                <td style="background-color:#fbfbdd"
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <span v-if="editableIndex !== index">{{ item.oplacheno }}</span>
                                    <input v-else v-model="editedValues.oplacheno" />
                                </td>

                                <td style="background-color:#fbfbdd"
                                    v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))">
                                    <span>{{ item.oplacheno }}</span>

                                </td>

                                <!-- Общая сумма тура -->
                                <td>{{ item.total_tour }} </td>
                                <!-- процент  -->
                                <td>{{ item.percent }}</td>




                                <td
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <span v-if="editableIndex !== index"> {{ item.total_rental_cost }}</span>
                                    <input v-else v-model="editedValues.total_rental_cost" />
                                </td>

                                <td
                                    v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))">
                                    <span> {{ item.total_rental_cost }}</span>

                                </td>

                                <td
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <span v-if="editableIndex !== index"> {{ item.doplata_tour }}</span>
                                    <input v-else v-model="editedValues.doplata_tour" />
                                </td>

                                <td
                                    v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))">
                                    <span> {{ item.doplata_tour }}</span>
                                </td>
                                <!-- вывод мотоциклов  -->
                                <td
                                    v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))">
                                    <!-- <span >{{ item.moto?.equipment_name }}</span> -->
                                    <span v-if="editableIndex !== index">{{ item.moto?.equipment_name }}</span>
                                    <select v-else v-model="editedValues.moto" @change="handleSelectChange">
                                        <option :value="null" disabled>Выберите мото</option>
                                        <option v-for="(moto2, motoIndex) in motos2" :key="motoIndex" :value="moto2.id">
                                            {{ moto2.equipment_name }}
                                        </option>
                                    </select>

                                </td>
                                <!-- редактирование мото -->
                                <td
                                    v-if="($store.state.userRole === 'user') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('user'))">
                                    <!-- <span >{{ item.moto?.equipment_name }}</span> -->
                                    <span>{{ item.moto?.equipment_name }}</span>

                                </td>
                                <!-- редактирование договор по туру  -->
                                <td>
                                    <div>
                                        <span v-if="item.dogovor_po_turu"> <a target="_blank"
                                                :href="`https://api.motoproject18.ru:3000/uploads_tour/${item.dogovor_po_turu}`">
                                                {{
            truncateString(item.dogovor_po_turu) }} </a></span>
                                        <span v-else>Нет данных</span>
                                        <input
                                            v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                            type="file" accept="application/pdf"
                                            @change="handleFileUpload('tour_contract', $event, item)" />
                                        <div v-if="loading">Загрузка...
                                            <div v-if="downloadLink">
                                                <p>файл загружен:</p>
                                                <a target="_blank" :href="downloadLink" :download="downloadLink"
                                                    v-if="downloadLink">{{
            fileName }}</a>
                                            </div>
                                        </div>

                                        <!--  -->
                                        <div v-if="fileUploadedMessage">
                                            <p>Файл успешно загружен для статуса {{ fileName }} </p>
                                            <!-- <a target="_blank" :href="downloadLink" :download="downloadLink"
                                                v-if="downloadLink">{{ fileName }}</a> -->
                                        </div>

                                    </div>
                                </td>
                                <td>
                                    <!-- договр на тур -->
                                    <div>
                                        <span v-if="item.dogovor_na_arendu"><a target="_blank"
                                                :href="`https://api.motoproject18.ru:3000/uploads_arenda/${item.dogovor_na_arendu}`">{{
            truncateString(item.dogovor_na_arendu) }}</a></span>
                                        <span v-else>Нет данных</span>

                                        <input type="file" accept="application/pdf"
                                            v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                            @change="handleFileUpload1('rental_contract', $event, item.status_id)" />
                                        <div v-if="loading1">Загрузка...</div>
                                        <div v-if="downloadLink1">
                                            <p>файл загружен:</p>
                                            <a target="_blank" :href="downloadLink1" :download="downloadLink1"
                                                v-if="downloadLink1">
                                                {{ fileName1 }}
                                            </a>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <span v-if="editableIndex !== index">{{ item.description }}</span>
                                    <input v-else v-model="editedValues.description" />

                                </td>

                                <td>
                                    <select v-if="editableIndex === index" v-model="editedValues.is_busy">
                                        <option :value="true">Забронировано</option>
                                        <option :value="false">Не забронировано</option>
                                    </select>
                                    <span v-else>{{ item.is_busy ? 'Забронировано' : 'Не забронировано' }}</span>
                                </td>
                                <td>
                                    <template v-if="!isEditing(index)">
                                        <!-- Добавляем кнопку "Редактировать" и "Удалить" -->
                                        <button @click="startEditingTable(index)">Редактировать</button>
                                        <button
                                            v-if="($store.state.userRole === 'admin') || ($store.state.user && $store.state.user.roles && $store.state.user.roles.includes('admin'))"
                                            @click="deleteItem(index)">Удалить</button>
                                    </template>

                                    <template v-else>
                                        <!-- Добавляем кнопку "Сохранить" и "Отмена" -->
                                        <button @click="saveItemTable(index)">Сохранить</button>
                                        <button @click="cancelEditTable(index)">Отмена</button>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
            <!-- <TableBiker v-if="showTable" /> -->
        </div>
    </div>
</template>

<script>

import axios from 'axios';
// компонент календаря
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
// import TableBiker from './TableBiker.vue';
import '@fortawesome/fontawesome-free/css/all.css';
// import ListBike from './ListBike.vue';
import { ref } from 'vue';
import { BASE_URL } from '../config'; // Импортируем базовый URL
import { useRouter } from 'vue-router';
// import transliteration from 'transliteration';
export default {

    components: {
        Datepicker,

    },

    setup() {
        // Установка в нормальном формате даты
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;

        };

        return {
            format,
        };

    },
    data() {
        return {

            uniqueBikesInResponse2: [], // Add this line
            selectedTourDateId: null,
            // данные для загрузки гидов
            tourDateId: null,
            // selectedTour: null,
            editingGuideIndex: null,
            editIndex1: null,
            motos: [],
            myDate: null,
            // contracts: [],
            guideOptions: [],
            selectedGuide: null,
            fileUploadedMessage1: null,
            newContractName: '',
            selectedGuideName: null,
            showAddGuideForm: false, // Новое свойство
            editingContract: null,
            editedValues1: {},
            showAddGuideEdit: false,
            uploading1: false,
            contracts: [
                {
                    // ... ваши другие свойства
                    selectedGuide: null, // добавьте это свойство
                },
            ],



            currentPage: 1,
            totalPages: 1,
            searchName: '',
            searchDescription: '',

            myMoto: '',
            pageNumber: 0,
            result: '',
            fileUploadedMessage: false,
            selectedTour: null,
            userDetails: [],
            isSaving: false,
            selectedTourIndex: null,
            selectedTourNameInModal: '',
            selectedTourName: '',
            editIndex: null,
            editedTourName: '',
            newDate: ref(''),
            selectedTourMessage: '',
            selectedTourMessage1: '',
            // isEditing: false,
            editingIndex: null,
            isModalVisible: false,//Это директива Vue, которая определяет, должен ли быть элемент отображен в зависимости от значения переменной isModalVisible. Если isModalVisible равен true, то элемент отображается, иначе скрывается.
            checkedMotos: [], // Array to store checked motos
            editedDate: {
                start: null,
                end: null,
                tour_date: new Date().toLocaleDateString('ru-RU'),
                tour_date_end: new Date().toLocaleDateString('ru-RU'),
                freeSpots: null, // Initialize appropriately during editing
            },
            motoArray: [],


            loading: false,
            loading1: false,
            downloadLink: null, // Store the download link
            downloadLink1: null,
            tours: [],
            showTable: false,
            showTotal: true,
            date: '', // добавлено
            freeSpots: 0, // добавлено
            originalData: [],
            selectedMotos: [],
            selectedMotos1: [],
            tableHeaders: [
                '№',
                'ФИО',
                'Аванс',
                '№ счета',
                'Оплачено',
                'Стоимость по туру',
                '%',

                'Стоимость аренды',
                'Доплата',
                'Арендная техника',
                'Договор по туру',
                'Договор на аренду',
                'Комментарий',
                'Гостиница',
                'Действие'
            ],
            uniqueFreeBikes: [], // Ваши уникальные элементы из запроса 1
            uniqueMotos1: [], // Ваши уникальные элементы из запроса 2
            bikeResults: [], // Результаты запросов для уникальных элементов из запроса 1
            motoResults: [], // Рез
            tableData: [],
            originalEditedDate: {
                start: null,
                end: null,
                freeSpots: null,
            },
            selectedFile: null,
            selectedMoto: null,
            motos1: [],
            editableIndex: null,
            editedValues: {
                fio: "",
                avance_tour: "",
                schet: "",
                oplacheno: "",
                total_rental_cost: "",
                tour_contract: "",
                rental_contract: "",
                doplata_tour: "",
                description: "",
                motoId: null,
                is_busy: false,
                percent: 0,
            },

        };

    },

    props: {
        listData: {
            type: Array,
            required: true
        },
        size: {
            type: Number,
            required: false,
            default: 10
        }
    },

    created() {
        const storedRoles = localStorage.getItem('setUserRole');
        console.log('Stored roles:', storedRoles);

        if (storedRoles) {
            try {
                const rolesArray = JSON.parse(storedRoles).roles;
                console.log('Restored roles:', rolesArray);
                this.roles = rolesArray;
                // Выполните здесь необходимые действия в зависимости от сохраненных ролей,
                // например, перенаправление на защищенную страницу.
            } catch (error) {
                console.error('Error parsing stored roles:', error);
            }
        }

        this.fetchTours();//туры
        this.fetchBikes();//мотики
        this.fetchContract();//контракты гидов


    },


    watch: {
        // отслеживает изменения переменной selectedGuide, которая представляет выбранный идентификатор гида.
        selectedGuide(newGuideId) {
            console.log('Выбранный ID гида:', newGuideId);

            // Поиск имени выбранного гида и установка selectedGuideName
            // Этот код ищет объект гида в массиве guideOptions, у которого идентификатор соответствует новому значению newGuideId.
            const selectedGuide = this.guideOptions.find(guide => guide.id === newGuideId);
            this.selectedGuideName = selectedGuide ? selectedGuide.guide_name : null;
            console.log('Выбранное имя гида:', this.selectedGuideName);

            // Вызываем дополнительный метод для отслеживания изменений
            this.handleSelectedGuideChange();

        },
    },
    async mounted() {
        const router = useRouter();
        const storedPath = localStorage.getItem('lastPath'); // Получаем сохранённый путь из локального хранилища

        // Если сохранённый путь существует, переходим на него
        if (storedPath) {
            router.push(storedPath);
        }

    },
    methods: {
        // сброс байктура
        resetBiketoor() {
            this.selectedTour = null;
        },
        //  Этот метод вызывается при вводе информации о турах. 
        handleInputTours() {
            this.name = ''
            this.fetchTours();
        },
        // Этот метод устанавливает идентификатор выбранной даты тура (selectedTourDateId). Возможно, используется для выбора конкретной даты тура.
        selectTourDate(tourDateId) {
            this.selectedTourDateId = tourDateId;
        },
        // handleSelectChange(): Этот метод вызывается при изменении выбранного мотоцикла. Он находит выбранный мотоцикл в списке motos2 по его идентификатору, обновляет myMoto (если есть мотик в базе с туристами , тогда он его не выводит , показывает только список свобдных).
        handleSelectChange() {
            const selectedMoto = this.motos2.find(moto => moto.id === this.editedValues.moto);
            this.myMoto = selectedMoto ? selectedMoto.equipment_name : '';

            console.log('Selected Moto ID:', this.editedValues.moto);
            console.log('myMoto:', this.myMoto);
            this.selectedMoto = selectedMoto;
        },
        // findUniqueElements(array1, array2): Этот метод находит уникальные элементы в массиве array1, которых нет в массиве array2.  Это вывод уникальных мото
        findUniqueElements(array1, array2) {
            return array1.filter(item => !array2.some(element => element.id === item.id));
        },
        // Этот метод проверяет, редактируется ли элемент с указанным индексом (index). Он возвращает true, если editableIndex равен index, иначе возвращает false. Вероятно, это используется для определения того, находится ли элемент в режиме редактирования.
        isEditing(index) {
            return this.editableIndex === index;
        },


        // редактирование таблицы дат
        async startEditingTable(index) {
            try {
                this.editingIndex = index;
                this.editableIndex = index;
                // При редактировании сохраняем текущее значение moto или оставляем null, если ничего не выбрано
                const currentMotoId = this.userDetails[index].moto?.id || null;
                this.editedValues = { ...this.userDetails[index], moto: currentMotoId };
                // Fetch motos data if not fetched yet
                if (!this.motos2.length) {
                    await this.fetchMotos();
                }
                console.log('Edited Values:', this.editedValues);
                console.log('Selected Moto ID:', this.editedValues.moto);
            } catch (error) {
                console.error('Error updating item on server:', error);
            }
        },
        // сохранение таблицы с датами тура
        async saveItemTable(index) {
            try {
                const statusId = this.userDetails[index]?.status_id;
                console.log('statusId', statusId)
                // Проверка, определено ли значение statusId
                if (statusId === undefined) {
                    throw new Error('statusId is not defined');
                }

                const requestData = {
                    is_busy: this.editedValues.is_busy,
                    avance_tour: this.editedValues.avance_tour,
                    schet: this.editedValues.schet,
                    moto: this.selectedMoto || null,
                    oplacheno: this.editedValues.oplacheno,
                    total_rental_cost: this.editedValues.total_rental_cost,
                    doplata_tour: this.editedValues.doplata_tour,
                    description: this.editedValues.description,
                };

                // Замените 'YOUR_API_ENDPOINT' на фактический конечный пункт вашего API
                const response = await axios.put(`${BASE_URL}/arenda-status/by-tour-date/${statusId}`, requestData);

                this.editableIndex = null;
                console.log(`${BASE_URL}/arenda-status/by-tour-date/${statusId}`)
                console.log('вот данные:', response.data);
                console.log('selectedMoto', this.selectedMoto)
                this.userDetails[index] = response.data
                // this.tours[this.selectedTour].dates[index] = response.data;
                // this.$forceUpdate();
            } catch (error) {
                console.error('Error updating item on server:', error);
            } finally {
                this.isSaving = false;
            }
        },



        // загрузка файла 
        async handleFileUpload(field, event, item) {
            if (!item || typeof item.status_id === 'undefined') {
                console.error('Variable "item" or "item.status_id" is undefined.');
                return;
            }

            const file = event.target.files[0];
            const fileURL = URL.createObjectURL(file);
            this.editedValues[field] = fileURL;

            this.selectedFile = {
                file,
                field,
            };

            this.uploadFile(item.status_id);

        },
        // Этот метод осуществляет фактическую загрузку файла на сервер для указанного статуса тура. Он отправляет запрос на сервер с помощью axios, прикрепляя выбранный файл к форме данных. После успешной загрузки файла, обновляет имя файла и ссылку для скачивания.
        uploadFile(status_id) {
            if (!this.selectedFile) {
                console.error('No file selected');
                return;
            }

            this.loading = true;

            const formData = new FormData();
            formData.append('image', this.selectedFile.file);


            axios.post(`${BASE_URL}/files/upload/${status_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                },
            })
                .then(response => {
                    const encodedString = response.data.data.originalname;

                    // Декодируем строку из байтов в Unicode
                    const decodedString = decodeURIComponent(escape(encodedString));

                    console.log(decodedString, 'файл')
                    this.fileName = decodedString;
                    this.downloadLink = `${BASE_URL}/uploads_tour/${response.data.data.filename}`;



                })

                .catch(error => {
                    console.error('Error uploading file:', error);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false; // Независимо от успеха или ошибки, снимаем флаг загрузки
                        this.fileUploadedMessage = false;
                        // Проверяем, соответствует ли статус текущему статусу, для которого должна быть отображена надпись
                        if (this.item && this.item.status_id && status_id === this.item.status_id) {
                            this.fileUploadedMessage = true;
                        }
                    }, 3000);
                });
        },

        // загрузка второго файла
        handleFileUpload1(field, event, status_id) {
            const file = event.target.files[0];
            const fileURL = URL.createObjectURL(file);
            this.editedValues[field] = fileURL;

            this.selectedFileRental = {
                file,
                field,
            };

            this.uploadFile1(status_id); // Automatically trigger the file upload
        },
        // Этот метод осуществляет фактическую загрузку файла на сервер для указанного статуса тура. Он отправляет запрос на сервер с помощью axios, прикрепляя выбранный файл к форме данных. После успешной загрузки файла, обновляет имя файла и ссылку для скачивания.
        uploadFile1(status_id) {
            if (!this.selectedFileRental) {
                console.error('No file selected');
                return;
            }

            this.loading1 = true;

            const formData = new FormData();
            formData.append('image', this.selectedFileRental.file);


            axios.post(`${BASE_URL}/files/upload1/${status_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                },
            })
                .then(response => {
                    const encodedString = response.data.data.originalname;

                    // Декодируем строку из байтов в Unicode
                    const decodedString = decodeURIComponent(escape(encodedString));

                    console.log(decodedString, 'файл')
                    this.fileName1 = decodedString;
                    this.downloadLink1 = `${BASE_URL}/uploads_arenda/${response.data.data.filename}`;
                })

                .catch(error => {
                    console.error('Error uploading file:', error);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false; // Независимо от успеха или ошибки, снимаем флаг загрузки
                        this.fileUploadedMessage = false;
                        // Проверяем, соответствует ли статус текущему статусу, для которого должна быть отображена надпись
                        if (this.item && this.item.status_id && status_id === this.item.status_id) {
                            this.fileUploadedMessage = true;
                        }
                    }, 3000);
                });
        },
        // Этот метод асинхронно вычисляет количество свободных мест на выбранную дату тура. Он сначала проверяет наличие информации о количестве общих мест для указанной даты. Если информация доступна, он вызывает метод calculateOccupiedSeats(date) для вычисления количества занятых мест. Затем возвращает разницу между общим количеством мест и количеством занятых.
        async getFreeSeats(date) {
            // Проверяем, что date и date.tourDate существуют
            if (date && date.tourDate && date.tourDate.totalSeats) {
                const totalSeats = date.tourDate.totalSeats;
                const occupiedSeats = await this.calculateOccupiedSeats(date);
                return totalSeats - occupiedSeats;
            } else {
                // Возвращаем какое-то значение по умолчанию, например, -1
                return -1;
            }
        },
        // Этот метод асинхронно отправляет запрос на сервер для получения информации о количестве занятых мест на выбранную дату тура. Если запрос успешен, возвращает количество занятых мест. В случае ошибки возвращает 0.
        async calculateOccupiedSeats(date) {
            try {
                const response = await axios.get(`${BASE_URL}/arenda-status/by-tour-date-all/${date.date_id}`);
                return response.data.length;
            } catch (error) {
                console.error('Ошибка при получении информации о занятых местах:', error);
                return 0; // Возвращаем 0 в случае ошибки
            }
        },



        //  Этот метод вызывается при клике на чекбокс для выбора мотоцикла. Он извлекает идентификаторы выбранных мотоциклов и передает их в метод 
        handleCheckboxClick(index) {
            const selectedMotoIds = this.selectedMotos.map(moto => moto.id);
            console.log('Selected Motorcycles IDs:', selectedMotoIds);
            this.addMotosToTour(selectedMotoIds);

            console.log(index);
        },

        // Сохранение мотоциклов к туру
        // Этот метод добавляет выбранные мотоциклы к выбранному туру. Он сначала проверяет наличие выбранного тура. Затем получает идентификатор тура и отправляет запрос на сервер для добавления выбранных мотоциклов к туру. После успешного добавления, обрабатывает ответ от сервера. В случае ошибки, выводит сообщение об ошибке.
        async addMotosToTour(selectedMotoIds, index) {
            // Check if a tour is selected
            console.log('index addMotosToTour ', index)
            if (this.selectedTour === null) {
                console.error('No tour selected to add motorcycles to.');
                // You might want to show a message to the user or handle it in another way
                return;
            }
            // Get the tour_id based on the selectedTour index
            const tourId = this.tours[this.selectedTour]._id;
            console.log('tourId', tourId)
            console.log('Selected Motorcycles IDs:', selectedMotoIds); // Use the parameter here

            // Continue with the rest of the method
            try {
                const response = await axios.put(`${BASE_URL}/tours/${tourId}/motos`, {
                    motoIds: selectedMotoIds,
                    addedToTour: true
                });

                // console.log(`${this.apiUrl}/tours/${tourId}/motos`);
                console.log('Motorcycles added to tour successfully:', response.data);
                console.log('вывод Tour ID:', tourId);
                console.log('Index:', index); // Log the index parameter
                // this.selectedMotos = [];
                // Handle the response as needed
            } catch (error) {
                console.error('Error adding motorcycles to tour:', error);
                // Handle the error as needed
            }
        },




        // показ всей техники арендной
        // Этот метод асинхронно отправляет запрос на сервер для получения списка доступных мотоциклов для указанного тура. Если запрос успешен, обрабатывает полученные данные и формирует список мотоциклов для отображения пользователю.
        async fetchBikes(tourId) {

            try {
                const response = await axios.get(`${BASE_URL}/moto`);
                this.motos = response.data.map(moto => {
                    return {
                        id: moto.id,
                        name: moto.equipment_name.trim(),
                        price: moto.price,
                    };
                });

                this.motos.forEach(moto => {
                    console.log(moto.id);
                });

                // выбранные мотики
                const response1 = await axios.get(`${BASE_URL}/tours/${tourId}/motos`);

                this.checkedMotos = [];
                // мотоциклы которые есть в бд не выбранные
                this.motos1 = response1.data.map(moto1 => {
                    return {
                        id: moto1.id,
                        name: moto1.equipment_name.trim(),
                        price: moto1.price,
                    };
                });
                // мотоциклы которые выбраны к туру
                this.motos2 = response1.data
                this.motos1.forEach(moto1 => {
                    console.log(moto1.id);
                });
                // После того, как вы получили оба списка мотоциклов
                this.selectedMotos = this.motos.filter(moto =>
                    this.motos1.some(moto1 => moto1.id === moto.id)
                );
            } catch (error) {
                console.error('Error fetching motos:', error);
            }
        },
        // модалка показать
        async showModal(index) {
            if (this.tours[index] && this.tours[index].name) {
                this.selectedTourName = this.tours[index].name;
                console.log('this.selectedTourName', this.selectedTourName)
                // Далее ваш код для отображения модального окна
                this.isModalVisible = true;
                console.log('this.selectedTourName', this.selectedTourName)
            }
        },
        // скрыть модалку
        hideModal() {
            this.isModalVisible = false;
            this.selectedMotos = [];
            this.selectedMotos1 = [];
        },

        // форматирование даты
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('ru-RU', options);
        },

        // Этот метод используется для отображения дат выбранного тура.
        //  Если selectedTour равен переданному индексу, то таблица дат скрывается и обнуляются соответствующие сообщения. 
        // В противном случае, делается запрос на сервер для получения информации о туре и его датах.
        //  Полученные данные обновляют список туров с соответствующими датами. 
        // Затем выбранный тур устанавливается в соответствие с переданным индексом, а также сбрасывается индекс редактирования. 
        // Также таблица скрывается, и сообщение о выбранном туре обнуляется. 
        // Затем вызывается метод fetchBikes(tourId), чтобы загрузить информацию о мотоциклах для этого тура.
        async showTourDates(index) {
            if (this.selectedTour === index) {
                // this.selectedTour = null;
                this.showTable = false;
                this.selectedTourMessage = ''
            } else {
                try {

                    console.log('index из ShowData', index)
                    this.selectedTourIndex = index;
                    // Запрос на получение информации о туре
                    const tourResponse = await axios.get(`${BASE_URL}/tours/${this.tours[index]._id}`);
                    console.log('Tour Response:', tourResponse.data.tour.tour_id);
                    const tourId = tourResponse.data.tour.tour_id
                    // Запрос на получение дат тура
                    const datesResponse = await axios.get(`${BASE_URL}/tour-dates/${this.tours[index]._id}/tour-date`);
                    console.log('Dates Response:', datesResponse.data);

                    // Обновление данных о туре в списке
                    this.tours = this.tours.map((tour, i) => {
                        if (i === index) {
                            return {
                                _id: tour._id,
                                name: tour.name,
                                description: tour.description,
                                dates: datesResponse.data,
                            };
                        }
                        return tour;
                    });

                    // Установка выбранного тура и сброс индекса редактирования
                    this.selectedTour = index;
                    this.editIndex = null;
                    // Добавьте эту строку, чтобы скрыть таблицу при выборе тура
                    this.showTable = false;
                    this.selectedTourMessage = ''
                    this.fetchBikes(tourId)
                    // сюда вставить
                } catch (error) {
                    console.error('Ошибка при загрузке тура и его дат:', error);
                }
            }
        },

        //     Этот метод загружает список туров с сервера с учетом параметров запроса, таких как пагинация, фильтрация и сортировка. 
        // Сначала обнуляются сообщения и таблица. Затем отправляется запрос на сервер для получения общего количества туров. Общее количество туров используется для вычисления общего количества страниц. Затем отправляется запрос на сервер для получения списка туров с учетом параметров запроса.
        //  Полученные данные обновляют список туров, который будет отображаться пользователю.
        async fetchTours() {
            //    this.showTourDates()
            try {
                // this.selectedTour = null;
                this.showTable = false;
                this.selectedTourMessage = ''
                const queryParameters = {
                    limit: 10,
                    skip: (this.currentPage - 1) * 10,
                    name: this.searchName,
                    description: this.searchDescription,
                    sort: 'asc', // Минус перед полем указывает на убывающий порядок
                };

                // Получение общего количества
                const totalCountResponse = await axios.get(`${BASE_URL}/tours/count`);
                console.log('Ответ по общему количеству:', totalCountResponse);

                const totalCount = totalCountResponse.data;
                console.log('Общее количество:', totalCount);

                // Обновление общего количества страниц
                this.totalPages = Math.ceil(totalCount / 10);

                // Получение туров с пагинацией и фильтрацией
                const response = await axios.get(`${BASE_URL}/tours`, { params: queryParameters });
                console.log('Ответ по турам:', response);

                // Предполагается, что response.data - это массив туров, обновите эту строку
                this.tours = response.data.map((tour) => ({
                    _id: tour.tour_id,
                    name: tour.tour_name,
                    description: tour.tour_description,
                    // Добавьте другие свойства по необходимости
                }))

            } catch (error) {
                console.error('Ошибка при получении туров:', error);
            }

        },


        // пагинация назад
        async prevPage() {

            if (this.currentPage > 1) {
                this.selectedTour = null;
                this.currentPage--;
                await this.fetchTours();
            }
        },
        // пагинация вперед
        async nextPage() {

            if (this.currentPage < this.totalPages) {

                this.selectedTour = null;
                this.currentPage++;
                await this.fetchTours();
            }
        },
        // удаление тура 
        async deleteTour(index) {
            const confirmation = confirm('Вы уверены, что хотите удалить этот тур?');
            if (confirmation) {
                const tourId = this.tours[index]._id;

                try {
                    await axios.delete(`${BASE_URL}/tours/${tourId}`);
                    this.tours.splice(index, 1);
                    this.selectedTour = null;
                } catch (error) {
                    console.error('Error deleting tour:', error);
                }
            }
        },

        // удаление даты тура
        async deleteDate(index) {
            const confirmation = confirm('Вы уверены, что хотите удалить дату этого тура?');
            if (confirmation) {
                // this.tours[this.selectedTour].dates.splice(dateIndex, 1);

                const dateId = this.tours[this.selectedTour].dates[index].date_id;
                console.log(dateId)
                try {
                    await axios.delete(`${BASE_URL}/tour-dates/${dateId}`);
                    this.tours[this.selectedTour].dates.splice(index, 1);
                } catch (error) {
                    console.error('Error deleting tour date:', error);
                }
            }
        },

        // сохранение тура
        saveTour(index) {
            this.tours[index].isEditing = false;

            const tourId = this.tours[index]._id;
            const requestData = new URLSearchParams();

            requestData.append('tour_name', this.tours[index].name);
            requestData.append('tour_description', this.tours[index].description); // Добавим tour_description

            axios.put(`${BASE_URL}/tours/${tourId}`, requestData.toString(), {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            })
                .then(response => {
                    console.log('Tour updated successfully:', response.data);
                    // Обновим значения в массиве туров
                    this.tours[index].name = response.data.tour_name;
                    this.tours[index].description = response.data.tour_description;
                })
                .catch(error => {
                    console.error('Error updating tour:', error);
                    this.fetchTours(); // Вернем данные к исходным
                });
        },



        // обновление тура 
        dateToString(date) {
            if (!(date instanceof Date)) {
                console.error('Invalid date object.');
                return '';
            }

            // Check if the date is valid
            if (isNaN(date.getTime())) {
                console.error('Invalid time value');
                return '';
            }

            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return date.toLocaleDateString('ru-RU', options);

        },
        // редактиррвание тура
        editTour(index) {
            this.editedTourName = this.tours[index].name;
            this.tours[index].isEditing = true;
        },
        // добавление нового тура
        async addNewTour() {
            try {
                const response = await axios.post(`${BASE_URL}/tours`, {
                    tour_name: 'Новый тур',
                    tour_description: 'Введите год',
                    // Добавьте другие поля тура, если необходимо
                });

                // Добавление нового тура в начало массива
                const newTour = { name: 'Новый тур', description: 'Введите год', dates: [] };
                this.tours.unshift(newTour);

                // Сортировка туров по убыванию даты создания (если у вас есть поле "createdAt")
                this.tours.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                this.fetchTours();
                // Обработка успешного добавления тура
                console.log('Tour added successfully:', response.data);
            } catch (error) {
                console.error('Error adding tour:', error);
            }
        },


        // // startEditing(dateIndex): Этот метод запускается при начале редактирования даты тура.

        startEditing(dateIndex) {
            const selectedTour = this.tours[this.selectedTour];
            // Проверяем, выбран ли тур, а также существует ли массив дат и существует ли указанный индекс даты
            if (!selectedTour || !selectedTour.dates || !selectedTour.dates[dateIndex]) {
                console.error('Invalid tour or date index.');
                return;// Если условие не выполнено, выводится сообщение об ошибке и метод завершается
            }

            const selectedDate = selectedTour.dates[dateIndex];
            // Если выбранная дата существует, создаем новый объект для редактирования, используя оператор расширения,
            // и преобразуем строку даты в объект JavaScript Date с помощью метода stringToDate
            if (selectedDate.tour_date) {
                // Создаем новый объект, используя оператор расширения
                this.editedDate = { ...selectedDate, start: this.stringToDate(selectedDate.tour_date) };
                // Устанавливаем флаг редактирования для выбранной даты на true
                selectedDate.isEditing = true;
            } else {
                console.error('Selected date or date property is undefined.');
                // Обрабатываем случай, когда свойство tour_date выбранной даты не определено
            }
        },

        // editTourDate(index): Этот метод запускается при редактировании даты тура.
        editTourDate(index) {
            if (this.selectedTour !== null && this.tours[this.selectedTour] && this.tours[this.selectedTour].dates) {
                const selectedDate = this.tours[this.selectedTour].dates[index];
                // Проверяем, существует ли выбранная дата и определено ли свойство tour_date для нее
                if (selectedDate && selectedDate.tour_date) {
                    // Save the original values before entering edit mode
                    this.originalEditedDate = {
                        start: selectedDate.tour_date,
                        end: selectedDate.tour_date_end,
                        totalSeats: selectedDate.totalSeats,
                        // freeSpots: selectedDate.freeSpots,
                    };

                    // Создаем копию даты для редактирования
                    this.editedDate = {
                        start: new Date(selectedDate.tour_date),
                        end: new Date(selectedDate.tour_date_end),
                        freeSpots: this.originalEditedDate.freeSpots,
                    };

                    // Устанавливаем флаг редактирования
                    selectedDate.isEditing = true;
                    // Set the edit index for canceling purposes
                    this.editIndex = index;
                } else {
                    console.error('Selected date or date property is undefined.');
                    // Handle the case where selectedDate.tour_date is undefined
                }
            } else {
                console.error('Invalid selectedTour or dates property.');
            }
        },




        // сохранение даты тура
        saveEdit(dateIndex) {
            const selectedTour = this.tours[this.selectedTour];
            if (!selectedTour || !selectedTour.dates || !selectedTour.dates[dateIndex]) {
                console.error('Invalid tour or date index.');
                return;
            }

            const selectedDate = selectedTour.dates[dateIndex];
            const formattedStartDate = this.editedDate.start.toISOString().split('T')[0].replace(/-/g, '.');
            const formattedEndDate = this.editedDate.end.toISOString().split('T')[0].replace(/-/g, '.');

            // Обновление локальных данных
            selectedDate.tour_date = `${formattedStartDate} - ${formattedEndDate}`;
            selectedDate.totalSeats = this.editedDate.totalSeats;
            selectedDate.isEditing = false;

            // Проверка наличия date_id
            if (selectedDate.date_id) {
                // Подготовка данных для PUT-запроса
                const requestData = {
                    tour_date: formattedStartDate,
                    tour_date_end: formattedEndDate,
                    totalSeats: this.editedDate.totalSeats,
                };

                // Выполнение PUT-запроса
                axios.put(`${BASE_URL}/tour-dates/${selectedDate.date_id}`, requestData)
                    .then(response => {
                        console.log('Date updated successfully:', response.data);
                        // Обновление selectedDate данными из ответа
                        Object.assign(selectedDate, response.data);
                    })
                    .catch(error => {
                        console.error('Error updating date:', error);
                    });
            } else {
                console.error('Invalid date_id for the selected date.');
            }
        },


        // отмена 
        cancelEdit(index) {

            const selectedTour = this.tours[index];
            if (selectedTour) {
                selectedTour.isEditing = false;
            } else {
                console.error('Invalid tour index.');
            }
        },



        // addNewTourDate(): Этот метод используется для добавления новой даты тура.
        async addNewTourDate() {
            if (this.selectedTour !== null) {
                if (!this.tours[this.selectedTour].dates) {
                    this.tours[this.selectedTour].dates = [];
                }

                try {
                    // Use the tour ID from the selected tour
                    const tourId = this.tours[this.selectedTour]._id;

                    // Создание объекта Date для текущей даты и времени
                    const currentDate = new Date();

                    // Форматирование даты в строку в формате 'YYYY-MM-DD'
                    const formattedDate = currentDate.toLocaleDateString('en-CA');

                    // Отправка POST-запроса для добавления новой даты тура с текущей датой и временем
                    const response = await axios.post(`${BASE_URL}/tour-dates`, {
                        tour: tourId,
                        tour_date: formattedDate,
                        tour_date_end: formattedDate,
                        totalSeats: 8,
                    });

                    // Извлечение объекта даты из ответа
                    const newDate = response.data;

                    // Добавление извлеченного объекта даты в массив dates
                    this.tours[this.selectedTour].dates.push(newDate);

                    // Логирование ответа сервера
                    console.log('Server response:', response.data);
                } catch (error) {
                    console.error('Error adding tour date:', error);
                }
            }
        },



        // cancelEditTourDate(index): Метод для отмены редактирования даты тура.
        cancelEditTourDate(index) {

            const selectedTour = this.tours[this.selectedTour];
            if (selectedTour && selectedTour.dates && selectedTour.dates[index]) {
                const selectedDate = selectedTour.dates[index];
                // this.item[index].isEditing = false;
                selectedDate.isEditing = false;
                // Сбросить значения обратно к оригиналу
                selectedDate.totalSeats = this.originalEditedDate.totalSeats;
            } else {
                console.error('Invalid tour or date index:', index);
            }
        },


        // saveTourDate(dateIndex): Метод для сохранения редактирования даты тура.
        saveTourDate(dateIndex) {
            this.editIndex = dateIndex;
            // Обработка сохранения редактирования даты тура
        },

        // deleTour(index): Метод для удаления тура.
        deleTour(index) {
            // Запрос подтверждения перед удалением тура
            const confirmation = confirm('Вы уверены, что хотите удалить этот тур, в туре не должно быть дат, иначе он не удалится?');
            if (confirmation) {
                this.tours.splice(index, 1);
                this.selectedTour = null;
            }
        },


        // generateMessage(date): Этот метод генерирует сообщение о доступных местах и обрабатывает различные запросы к серверу.
        async generateMessage(date) {
            try {
                if (!this.tours[this.selectedTour] || !this.tours[this.selectedTour].name) {
                    this.showTable = false;
                    this.selectedTourMessage = '';
                    this.selectedTourMessage1 = '';
                    this.freeBikes = [];
                    this.motos1 = [];
                    this.bikeResults = [];
                    this.motoResults = [];
                } else {
                    console.log('1', date.date_id);
                    const response = await axios.get(`${BASE_URL}/arenda-status/by-tour-date-all/${date.date_id}`);
                    console.log(`${BASE_URL}/arenda-status/by-tour-date-all/${date.date_id}`);
                    console.log('User Details Response:', response);
                    this.userDetails = response.data || [];

                    const selectedTour = this.tours[this.selectedTour];
                    if (selectedTour && selectedTour.dates) {
                        const tourDate = selectedTour.dates.find(tourDate => tourDate.date_id === date.date_id);

                        if (tourDate) {
                            const totalSeats = tourDate.totalSeats;
                            console.log(totalSeats);
                            const occupiedSeats = await this.calculateOccupiedSeats(date);
                            const freeSeats = totalSeats - occupiedSeats;
                            const tourDateId = date.date_id;
                            console.log('date.date_id:', date.date_id);

                            if (tourDateId) {
                                await this.fetchContract(tourDateId);
                                await this.fetchGuides(tourDateId);
                            } else {
                                console.error('tourDateId is undefined.');
                            }

                            console.log('User Details:', this.userDetails.length);
                            console.log(freeSeats);
                            this.selectedEquipmentName = this.userDetails[0]?.user?.moto?.equipment_name;
                            this.showTable = true;
                            this.selectedTourMessage = `Количество свободных мест: ${freeSeats}`;

                            const responseT = await axios.get(`${BASE_URL}/tour-dates/${date.date_id}`);
                            console.log('responseT', responseT.data);

                            const response1 = await axios.get(`${BASE_URL}/arenda-status/all-motos-date/${date.date_id}`);
                            console.log(response1);

                            const response2 = await axios.get(`${BASE_URL}/tours/${responseT.data}/motos`);
                            console.log(response2.data);

                            if (response2.data.length === 0) {
                                const freeBikes = response1.data.map(bike => ({ id: bike.id }));
                                const motos1 = response2.data.map(moto => ({ id: moto.id }));

                                const uniqueFreeBikes = this.findUniqueElements(freeBikes, motos1);
                                const uniqueMotos1 = this.findUniqueElements(motos1, freeBikes);

                                this.uniqueFreeBikes = [];
                                this.uniqueMotos1 = [];
                                this.motoResults = [];

                                this.uniqueFreeBikes.push(...uniqueFreeBikes);
                                this.uniqueMotos1.push(...uniqueMotos1);

                                console.log('data', date);
                                for (const moto of this.uniqueMotos1) {
                                    try {
                                        const response3 = await axios.get(`${BASE_URL}/moto/${moto.id}`);
                                        this.motoResults.push(response3.data);
                                        console.log(`Результат запроса для moto.id=${moto.id}:`, response3.data);
                                    } catch (error) {
                                        console.error(`Ошибка при запросе для moto.id=${moto.id}:`, error);
                                    }
                                }
                            } else {
                                const motos1 = response2.data.map(moto => ({ id: moto.id }));
                                console.log(response2.data);
                                console.log(motos1);
                                const uniqueMotos1 = this.findUniqueElements(motos1, []);
                                this.uniqueFreeBikes = [];
                                this.uniqueMotos1 = [];
                                this.motoResults = [];

                                this.uniqueMotos1.push(...uniqueMotos1);

                                console.log('data', date);
                                // свободные мотоциклы сравнение двух массивов
                                for (const moto of this.uniqueMotos1) {
                                    try {
                                        const response3 = await axios.get(`${BASE_URL}/moto/${moto.id}`);
                                        this.motoResults.push(response3.data);
                                        console.log(`Результат запроса для moto.id=${moto.id}:`, response3.data);
                                    } catch (error) {
                                        console.error(`Ошибка при запросе для moto.id=${moto.id}:`, error);
                                    }
                                }

                                console.log('Response 1 Data:', response1.data);
                                console.log('Response 2 Data:', response2.data);
                                console.log('Unique Motos 1:', this.uniqueMotos1);
                                console.log('Moto Results:', this.motoResults);

                                const response1Ids = response1.data.map(bike => bike && bike.id);

                                const uniqueBikesInResponse2 = response2.data.filter(bike => !response1Ids.includes(bike.id));
                                this.uniqueBikesInResponse2.value = uniqueBikesInResponse2;
                                console.log("Сравнение")
                                console.log('Response 1 IDs:', response1Ids);

                                console.log('Response 2 IDs:', response2.data.map(bike => bike.id));
                                console.log('Unique Bikes in Response 2:', uniqueBikesInResponse2);
                            }
                        } else {
                            console.error('Не удалось найти дату тура.');
                        }
                    } else {
                        console.error('Не удалось найти тур или даты тура.');
                    }
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response && error.response.status === 404) {
                        console.error('Ошибка 404: Ресурс не найден.');
                    } else {
                        console.error('Ошибка Axios:', error.message);
                    }
                } else {
                    console.error('Произошла ошибка:', error);
                }
            }
        },




        // editItem(index): Этот метод устанавливает флаг "editable" элемента в таблице по указанному индексу, чтобы разрешить редактирование.
        editItem(index) {
            this.tableData[index].editable = true;
        },
        // deleteItem(index): Этот метод используется для удаления элемента из таблицы по указанному индексу.

        async deleteItem(index) {
            console.log(index)
            const dateIdToDelete = this.userDetails[index]?.status_id;
            const confirmation = confirm('Вы уверены, что хотите удалить дату этого тура?');
            if (confirmation) {
                // this.tours[this.selectedTour].dates.splice(dateIndex, 1);


                try {
                    await axios.delete(`${BASE_URL}/arenda-status/by-tour-date/${dateIdToDelete}`);
                    // this.tours[this.selectedTour].dates.splice(index, 1);
                    this.userDetails.splice(index, 1);
                } catch (error) {
                    console.error('Error deleting tour date:', error);
                }
            }
        },

        saveItem(index) {
            this.tableData[index].editable = false;
        },
        cancelEditTable(index) {
            // Отменить редактирование участника
            if (this.isEditing(index)) {
                this.editableIndex = null;
                console.log('1');
            }
            // Если вы в режиме добавления, просто сбросьте значения editedValues
            else if (this.isAdding()) {
                this.editedValues = {};
                this.showTable = false;
            }
        },

        uploadDocument(index, key) {
            const input = event.target;
            if (input.files && input.files[0]) {
                this.tableData[index].isUploading = true;

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.tableData[index][key] = e.target.result;
                    this.tableData[index].isUploading = false;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },

        addParticipant() {
            const newParticipant = {
                '№': this.tableData.length + 1,
                'ФИО': 'Новый участник',
                'Аванс': 0,
                '№ счета': 0,
                'Оплачен': 'Нет',
                'Арендная техника': 'Нет',
                'Стоимость аренды': 0,
                'Доплата': 0,
                'Договор по туру': '',
                'Договор на аренду': '',
                'Комментарий': 'Нет комментария',

            };

            this.tableData.push(newParticipant);

        },
        truncateString(str) {
            // Вернуть первые 10 символов строки
            return str.substring(0, 10);
        },

        // добавление гида и отображение контрактов
        async handleFileUploadContract(field, event, index) {
            const contract = this.contracts[index];

            if (!contract || typeof contract.id === 'undefined') {
                console.error('Variable "contract" or "contract.id" is undefined.');
                return;
            }

            const file = event.target.files[0];
            const fileURL = URL.createObjectURL(file);
            this.editedValues1[field] = fileURL;

            this.selectedFile = {
                file,
                field,
            };

            this.uploadFileContract(index);
        },

        // загрузка файлов
        async uploadFileContract(index) {
            if (!this.selectedFile) {
                console.error('No file selected');
                return;
            }
            this.uploading1 = true;
            const contractId = this.contracts[index].id;

            console.log('хэндл', contractId)
            // this.loading = true;

            const formData = new FormData();
            formData.append('image', this.selectedFile.file);


            await axios.post(`${BASE_URL}/files/upload-contract-pdf/${contractId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                },
            })
                .then(response => {
                    console.log('url', `${BASE_URL}/files/upload-contract-pdf/${contractId}`)
                    const encodedString = response.data.data.originalname;

                    // Декодируем строку из байтов в Unicode
                    const decodedString = decodeURIComponent(escape(encodedString));

                    console.log(decodedString, 'файл')
                    this.fileName = decodedString;
                    this.downloadLink = `${BASE_URL}/uploads_gid/${response.data.data.filename}`;
                    this.fileUploadedMessage1 = `Файл успешно загружен для контракта ${this.contracts[index].name}`;
                    this.fetchContract(this.myDate);



                })

                .catch(error => {
                    console.error('Error uploading file:', error);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.fetchGuides();

                        this.uploading1 = false;

                    }, 1000);
                });
        },
        // конец
        // контракт гида редактирование
        editContract(index) {
            // Задаем контракт для редактирования и открываем форму
            this.contracts[index].isEditing = true;
            this.editingContract = { ...this.contracts[index] };
            // this.showAddGuideForm = true;
        },
        // показать форму загрузки контракта гида
        showEdidContract(index) {
            // this.contracts[index].isEditing = false;
            this.editingContractIndex = index;
            this.editingContract = null;
            this.showAddGuideEdit = true;
            this.editingGuideIndex = this.editingGuideIndex === index ? null : index;
            this.selectedGuide = this.contracts[index].guide_id;

        },
        // сохранение выбранного контракта гида
        saveContractChanges() {
            // Сохраняем изменения и закрываем форму
            // Ваша логика сохранения изменений
            this.editingContract = null;
            this.showAddGuideForm = false;
        },
        // показать форму с гидом 
        toggleAddGuideForm() {
            this.showAddGuideForm = !this.showAddGuideForm;
        },
        // выбор гида
        handleGuideChange() {
            console.log('Выбранный ID гида:', this.selectedGuide);
            if (this.selectedGuide) {
                const selectedGuide = this.guideOptions.find(guide => guide.id === this.selectedGuide);
                this.selectedGuideName = selectedGuide ? selectedGuide.guide_name : null;
                console.log('Выбранное имя гида:', this.selectedGuideName);
            }

            // Вызываем дополнительный метод для отслеживания изменений
            this.handleSelectedGuideChange();
        },


        // подгрузка гидов
        async fetchGuides() {
            try {
                const response = await axios.get(`${BASE_URL}/guides`);
                this.guideOptions = response.data;
                await this.handleSelectedGuideChange();
                console.log('tourDateId перед вызовом addNewContract:',);



                console.log("fetchGuides tourDateId");
                await this.saveContract(this.selectedGuide);
            } catch (error) {
                console.error('Error fetching guides:', error);
            }
        },



        handleSelectedGuideChange() {
            console.log('Вызван метод handleSelectedGuideChange');
            console.log('Выбранный ID гида:', this.selectedGuide);
            // console.log('Выбранное имя гида:', this.selectedGuideName);
        },
        // показ всех контактов
        async fetchContract(tourDateId) {
            try {
                // Добавьте проверку на null
                if (tourDateId === null) {
                    console.error('tourDateId is null');
                    return;
                }
                // console.log('tourDateId', `${tourDateId}`)
                // console.log(`тест ${this.apiUrl}/guide-contracts/by-date/${tourDateId}`)
                const response = await axios.get(`${BASE_URL}/guide-contracts/by-date/${tourDateId}`);
                // this.addNewContract(tourDateId);
                console.log('API Response:', response);
                console.log('API Data:', response.data);
                // const dateId = this.tours[this.selectedTour].dates[index].date_id;
                // console.log('Дата айди', dateId)

                this.contracts = response.data.map(contract => {
                    return {
                        id: contract.contract_id,
                        name: contract.contractDetails ? contract.contractDetails.trim() : '',
                        guide_name: contract.guide.guide_name ? contract.guide.guide_name.trim() : '', // Используйте guide_name здесь
                        // phone: guide.guide_phone ? guide.guide_phone.trim() : '',
                        // Add other properties based on your data structure
                        isEditing: false, // You may want to add this property
                    };
                });

                // Log names of all guides
                this.contracts.forEach(contract => {
                    console.log(contract.id);
                    console.log(contract.name)
                });
                this.myDate = tourDateId
                //  await this.addNewContract(this.myDate)
                // console.log('myDate', myDate)
            } catch (error) {
                console.error('Error fetching guides:', error);
            }
        },

        // добавление контакта
        async addNewContract() {
            try {
                console.log('myDate', this.myDate)

                // Отправка POST-запроса для добавления нового контакта
                const response = await axios.post(`${BASE_URL}/guide-contracts/${this.myDate}/${this.selectedGuide}/create-guide-contract`, {
                    contractDetails: '',  // Убедитесь, что здесь указано правильное поле
                    // guide_phone: 'Введите телефон или заметку'
                    // Добавьте другие поля тура, если необходимо
                });
                console.log(`${BASE_URL}/guide-contracts/${this.myDate}/${this.selectedGuide}/create-guide-contract`)
                this.contracts.push({ contractDetails: '' });

                // Обработка успешного добавления тура
                console.log('Гид добавлен:', response.data);
                const response1 = await axios.get(`${BASE_URL}/guide-contracts/by-date/${this.myDate}`);
                this.contracts = response1.data.map(contract => {
                    return {
                        id: contract.contract_id,
                        name: contract.contractDetails ? contract.contractDetails.trim() : '',
                        guide_name: contract.guide.guide_name ? contract.guide.guide_name.trim() : '', // Используйте guide_name здесь
                        isEditing: false, // You may want to add this property
                    };
                });
                // Опционально: обновление списка туров после добавления нового тура

                this.showAddGuideForm = false
                // this.fetchContract();
            } catch (error) {
                console.error('Error adding tour:', error);
            }
        },




        // удалить гида вместе с договором из тура

        async deleteContract(index) {
            try {
                const confirmation = confirm('Вы уверены, что хотите удалить этого гида вместе с договором?');
                if (confirmation) {
                    const contractId = this.contracts[index].id;

                    try {
                        await axios.delete(`${BASE_URL}/guide-contracts/delete-guide-contract/${contractId}`);
                        // console.log(`${this.apiUrl}/moto/${contractId}`);
                        this.guides.splice(index, 1); // Remove the deleted moto directly
                        this.selectedTour = null;
                    } catch (error) {
                        console.error('Error deleting moto:', error);
                    }
                    this.fetchContract(this.myDate)
                }
            } catch (error) {
                console.error('Error handling delete confirmation:', error);
            }
        },
        // сохранение
        async saveContract(index) {
            this.contracts[index].isEditing = false;
            const contractId = this.contracts[index].id;
            const guideId = this.selectedGuide; // Получаем guideId

            console.log('обновление', guideId);

            try {
                // Отправка PUT-запроса для обновления контракта
                const response = await axios.put(`${BASE_URL}/guide-contracts/update-guide-by-contract/${contractId}`, {
                    // Ваши данные для обновления контракта
                    // ...
                    guideId: guideId, // Добавляем guideId в данные запроса
                }, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                });

                console.log('Контракт обновлен успешно:', response.data);
                // Обновите необходимые данные в контракте, например:
                // this.contracts[index].name = response.data.contractDetails;

                // Вызываем метод для обновления данных туров
                this.fetchContract(this.myDate);
                this.showAddGuideForm = false
            } catch (error) {
                console.error('Ошибка при обновлении контракта:', error);
                // Обработка ошибки, если необходимо
            }
        },


        // преобразование даты тура при сохранении
        stringToDate(string) {
            const [day, month, year] = string.split('.');
            return new Date(`${year}-${month}-${day}`);
        },

    },

    computed: {
        // Этот вычисляемый свойство используется для обрезки строки "dogovor_po_turu" до первых 10 символов. 
        truncatedDogovorPoTuru() {
            if (this.items.length > 0) {
                // Вернуть первые 10 символов строки
                return this.items[0].dogovor_po_turu.substring(0, 10);
            }
            return '';
        },
        // Это вычисляемое свойство используется для определения общего количества страниц пагинации. 
        pageCount() {
            let l = this.listData.length,
                s = this.size;
            return Math.ceil(l / s);
        },
        // Это вычисляемое свойство используется для отображения данных на текущей странице пагинации. 
        paginatedData() {
            const start = this.pageNumber * this.size,
                end = start + this.size;
            return this.listData
                .slice(start, end);
        }

    },

};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
}

input {
    /* width:100% */
}

.modal {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80vw;
    height: 80vh;
    /* Set a maximum height for the modal content */
    overflow: auto;
    /* Enable vertical scrolling */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
}



button {
    margin-left: 2px;
}

/* кнопки */
.edit-button,
.delete-button {
    padding: 10px;
    margin: 5px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-button:hover,
.delete-button:hover {
    background-color: #2980b9;
}

.edit-button i,
.delete-button i {
    margin-right: 5px;
}

/* конец кнопок */
.editData {
    display: flex;
    justify-content: center;
}

.all {
    display: flex;

    /* flex-flow: row wrap; */
    /* margin: 0 auto; */
    max-width: 1200px;
    justify-content: space-between;
}


.padding_palace {
    padding-right: 20px;
    /* Добавление стилей, чтобы текст выглядел как ссылка при наведении курсора */
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.padding_palace:hover {
    /* Изменение цвета текста при наведении курсора */
    color: red;
}

.padding_palace:active {
    /* Изменение цвета текста при клике */
    color: green;
}

/* Стилизация для внешнего вида списка туров и дат */
.tour-menu {
    float: left;
    width: 400px;
    margin-right: 10px;
}

.tour-menu ul {
    list-style: none;
    padding: 0;
    cursor: pointer;
}

.tour-menu ul li {
    margin: 5px 0;
    color: blue;
}

.tour-menu ul li:hover {
    text-decoration: underline;
}

.tour-dates {
    clear: both;
    /* display: flex;
    flex-wrap: wrap; */
}

.tour-dates ul {
    list-style: none;
    padding: 0;
}

.tour-dates ul li {
    margin: 5px 0;
    color: green;
}


/* Стили для таблицы с деталями пользователя и датами тура */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table {

    overflow-x: auto;
    /* Включить горизонтальную прокрутку, если содержимое не помещается */
}

/* Стили для ячеек и заголовков */
th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    width: auto;
}

th {
    background-color: #f2f2f2;
}

/* Убрать маркер у всех элементов списка */
ul.freeBikes {
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* Убрать маркер у конкретных элементов списка внутри ul с id "myList" */
#myList li {
    list-style-type: none;
}

@media(max-width:1000px) {
    .all {
        display: flex;

        flex-flow: row wrap;
        /* margin: 0 auto; */
        max-width: 1200px;
        justify-content: space-between;
    }

}
</style>