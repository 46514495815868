<template>
  <div class="login-form">
    <h1>Система учета заказов</h1>
    <p>Создано фрилансерами для фрилансеров</p>
    <h2>Login</h2>


    <form v-if="!showRecoveryForm && !showRegistrationForm" @submit.prevent="login" class="form">
      <div v-if="showRegistrationSuccessMessage" class="success-message">
        Вы успешно зарегистрированы. Введите свой логин и пароль.
      </div>
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" required class="form-control">
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" required class="form-control">
      </div>
      <!-- Кнопка перехода к форме регистрации -->
      <button @click="showRegistrationForm = true" type="button" class="btn-link">Зарегистрироваться</button>
      <div v-if="loginError" class="error-message">
        Ошибка: Неверный логин или пароль
      </div>

      <button type="submit" class="btn">Login</button>
    </form>

    <div class="forgot-password" v-if="!showRecoveryForm && !showRegistrationForm  ">
      <p>Забыли ваш пароль?</p>
      <button @click="(showRecoveryForm = true)||(showRegistrationForm = true)" class="btn-link">Восстановить</button>
    </div>



    <!-- Форма восстановления -->
    <form v-if="showRecoveryForm " @submit.prevent="recover" class="form">
      <h2>Восстановить Login/Password</h2>
      <div class="form-group">
        <label for="recovery-email">Email:</label>
        <input type="email" id="recovery-email" v-model="recoveryEmail" required class="form-control">
      </div>
      <button type="submit" class="btn">Восстановить </button>
      <button  @click="showRecoveryForm = false" type="button" class="btn-link">Отменить</button>
    </form>


    <!-- Форма регистрации -->
    <form v-if="showRegistrationForm" @submit.prevent="register" class="form">
      <div v-if="registrationErrorMessage" class="error-message">
      {{ registrationErrorMessage }}
    </div>
      <h2>Регистрация</h2>
      <div class="form-group">
        <label for="register-username">Username:</label>
        <input type="text" id="register-username" v-model="registerUsername" required class="form-control">
      </div>
      <div class="form-group">
        <label for="register-password">Password:</label>
        <input type="password" id="register-password" v-model="registerPassword" required class="form-control">
      </div>
      <div class="form-group">
        <label for="register-email">Email:</label>
        <input type="email" id="register-email" v-model="registerEmail" required class="form-control">
      </div>
      <button type="submit" class="btn">Register</button>
      <!-- Кнопка отмены регистрации -->
      <button @click="showRegistrationForm = false" type="button" class="btn-link">Отменить</button>
   
      
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { BASE_URL } from '../config';
export default {
  data() {
    return {
      username: '',
      password: '',
      showRecoveryForm: false,
      recoveryEmail: '',//•	recoveryEmail: Строка, содержащая электронную почту для восстановления пароля
      protectedResourceData: null,//•	protectedResourceData: Объект, содержащий данные защищенного ресурса.
      protectedResourceData1: null,//•	protectedResourceData1: Объект, содержащий данные второго защищенного ресурса.
      roles: [],//роли пользователей
      user: '',
      loginError: false, // Переменная состояния для отображения ошибки
      // Добавляем переменные для данных регистрации
      registerUsername: '',
      registerPassword: '',
      registerEmail: '',
      // Добавляем переменную состояния для отображения формы регистрации
      showRegistrationForm: false,
      showRegistrationSuccessMessage: false,
      registrationErrorMessage: '',
      // roles:''
    };
  },
  // mounted(): Хук жизненного цикла, вызываемый после того, как компонент был вставлен в DOM.
  async mounted() {
    const storedRoles = localStorage.getItem('setUserRole');
    console.log('Stored roles:', storedRoles);

    if (storedRoles) {
      try {
        const rolesArray = JSON.parse(storedRoles).roles;
        console.log('Restored roles:', rolesArray);
        this.roles = rolesArray;
        this.getProtectedResource1()
        // ... далее ваш код ...
      } catch (error) {
        console.error('Error parsing stored roles:', error);

      }
    }

  },
  methods: {

    async register() {
      
      try {
        const response = await fetch(`${BASE_URL}/admin/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            login: this.registerUsername,
            password: this.registerPassword,
            email: this.registerEmail
          }),
        });

        if (response.ok) {
          // Обработка успешной регистрации
          // Например, перенаправление на страницу входа
          this.showRegistrationForm = false;
          this.showRegistrationSuccessMessage = true; // Показать сообщение о успешной регистрации
          // Очистить данные формы
          this.registerUsername = '';
          this.registerPassword = '';
          this.registerEmail = '';
          // Другие действия после успешной регистрации...
        }
        else if (response.status === 409) {
          // Пользователь уже существует
          this.registrationErrorMessage = 'Такой пользователь уже существует. Пожалуйста, придумайте новый логин.';
        } else {
          throw new Error('Registration failed');
        }
      } catch (error) {
        console.error('Registration error:', error);
        this.registrationErrorMessage = 'Произошла ошибка при регистрации. Такой логин уже есть. Пожалуйста, попробуйте еще раз.';
        // Обработка ошибки регистрации
        // Например, отображение сообщения об ошибке
      }
    },


    async checkTokenAndFetchResource(token) {
      try {
        if (token) {
          await this.getProtectedResource1(token);

        }
      } catch (error) {
        console.error('Error fetching protected resource:', error);
      }
    },
    // вход
    async login() {
      try {
        if (this.loginError) {
          return; // Если есть ошибка входа, просто выходим из функции без выполнения дальнейших действий
        }

        const response = await axios.post(`${BASE_URL}/auth/login`, {
          login: this.username,
          password: this.password,
        }, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const token = response.data.access_token;

        // Сохраняем токен в localStorage
        localStorage.setItem('token', token);

        const user = response.data.user.login; // Данные пользователя с сервера
        const roles = response.data.user.role
        console.log('тут roles', roles)
        console.log('тут user', user)
        // Manually update the roles in the component's state
        this.roles = roles;
        this.user = user;
        this.$store.commit('SET_USER', user); // Вызываем мутацию для сохранения данных пользователя в состояние Vuex
        this.$store.commit('setUserRole', roles);
        localStorage.setItem('setUserRole', JSON.stringify(roles));

        console.log('JSON.stringify(roles)', JSON.stringify(roles))
        // Далее ваш код для перехода на защищенный ресурс
        this.getProtectedResource();
      } catch (error) {
        console.error('Login error:', error);
        this.loginError = true;
      }
    },


    // Метод для получения защищенного ресурса BeerTOken
    async getProtectedResource() {
      try {
        const token = localStorage.getItem('token');
        // Посылаем GET-запрос на защищенный ресурс с токеном
        const response = await axios.get(`${BASE_URL}/auth/protected-resource`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const user = response.data.user;
        console.log('Loaded user data:', user);

        // Update the user data in the component's state
        this.user = user;

        // Optionally, update the Vuex store or other components
        this.$store.commit('SET_USER', user);
        console.log('Protected Resource:', response.data);
        this.protectedResourceData = response.data;
        console.log('protectedResourceData', this.protectedResourceData)
        this.$router.push('/allzakaz');
      } catch (error) {
        console.error('Error getting protected resource:', error);
      }
    },

    // Метод для получения второго защищенного ресурса.
    async getProtectedResource1() {
      try {
        const token = localStorage.getItem('token');
        const roles = localStorage.getItem('setUserRole');

        console.log('getProtectedResource1(roles)', roles);

        // Подождем, пока роли будут извлечены
        await new Promise((resolve) => setTimeout(resolve, 100));

        const response1 = await axios.get(`${BASE_URL}/auth/protected-resource`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const user1 = response1.data.user;
        console.log('Loaded user data1:', user1);

        // Update the user data in the component's state
        this.user = user1;

        // Optionally, update the Vuex store or other components
        this.$store.commit('SET_USER', user1);
        this.protectedResourceData1 = response1.data;
        console.log('Protected Resource1:', this.protectedResourceData1);
        return this.$router.push('/allzakaz');
      } catch (error) {
        console.error('Error getting protected resource:', error);
      }
    },

  }


};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
}

.login-form {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
}

.btn {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-link {
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  text-decoration: underline;
}

.btn:hover {
  background-color: #2980b9;
}
.error-message{
  color:red;
}
.success-message{
  background-color: green;
border-radius: 5px;
}
</style>
