<template>
  <div>
    <div class="container">
      <div class="customers">
        <h2>Список заказчиков</h2>
        <h3>Добавить заказчика:</h3>
        <form @submit.prevent="addCustomer">
          <div class="customerInput">
            <input type="text" v-model="newCustomerFIO" required placeholder="ФИО">
            <input type="text" v-model="newCustomerVk" placeholder="Профиль VK">
            <input type="text" v-model="newCustomerTelega" placeholder="Профиль Telegram">
            <input type="text" v-model="newCustomerWhatsapp" placeholder="Профиль WhatsApp">
          </div>

          <textarea type="text" style="width: 724px; height: 28px;" v-model="newCustomerComment"
            placeholder="Комментарий"></textarea><br>
          <button type="submit">Добавить заказчика</button>
        </form>
        <h3>Поиск заказчиков:</h3>
        <input type="text" v-model="searchQuery1" placeholder="Поиск...">
        <button @click="clearSearch1" class="clear-button"><i class="fas fa-times"></i></button>
        <ul>
          <li v-for="customer in filteredCustomers" :key="customer.id">
            {{ customer.fio }}
            <span v-if="customer.vk"><b>Vk:</b> {{ customer.vk }} </span>
            <span v-if="customer.telegram"> <b>Telegram:</b> {{ customer.telegram }} </span>
            <span v-if="customer.whatsapp"><b>WhatsApp:</b> {{ customer.whatsapp }} </span>
            <span v-if="customer.comment"> <b>Комментарии:</b> {{ customer.comment }} </span>
            <span v-if="customer.birzha"> <b>Биржа:</b> {{ customer.birzha }} </span>
            <button @click="editCustomer(customer)" class="edit-button"><i class="fas fa-pencil-alt"></i></button>
            <button @click="deleteCustomer(customer.id)" class="delete-button"><i class="fas fa-trash-alt"></i></button>
            <!-- Кнопки Редактировать и Удалить -->
            <hr>

            <!-- Инпуты для редактирования данных заказчика -->
            <div v-if="customer.editMode !== undefined && customer.editMode">
              <input type="text" v-model="customer.fio" placeholder="ФИО">
              <input type="text" v-model="customer.vk" placeholder="VK">
              <input type="text" v-model="customer.telegram" placeholder="Телеграм">
              <input type="text" v-model="customer.whatsapp" placeholder="Ватсап">
              <textarea type="text" v-model="customer.comment" placeholder="Коменты"></textarea>
              <!-- Кнопка Сохранить -->
              <button @click="updateCustomer(customer)">Сохранить</button>
              <button @click="cancelCustomer(customer)">Отменить</button>
            </div>


          </li>

        </ul>

      </div>
      <div class="performers">
        <h2>Список исполнителей</h2>
        <h3>Добавить исполнителя:</h3>

        <form @submit.prevent="addPerformer">
          <input type="text" v-model="newPerformerFIO" required placeholder="ФИО">
          <input type="text" v-model="newPerformerPhone" placeholder="Телефон">
          <input type="text" v-model="newPerformerVk" placeholder="Профиль VK">
          <input type="text" v-model="newPerformerTelega" placeholder="Профиль Telegram">
          <input type="text" v-model="newPerformerWhatsapp" placeholder="Профиль WhatsApp">
          <input type="text" v-model="newPerformerBirzha" placeholder="Профиль на бирже">
          <textarea type="text" v-model="newPerformerComment" style="min-width: 300px; height: 50px;"
            placeholder="Комментарий"></textarea>
          <textarea type="text" v-model="newPerformerSubject" style="min-width: 300px; height: 50px;"
            placeholder="Список предметов"></textarea>
          <button type="submit">Добавить исполнителя</button>
        </form>

        <h3>Поиск исполнителей:</h3>
        <input type="text" v-model="searchQuery" placeholder="Поиск...">
        <button @click="clearSearch" class="clear-button"><i class="fas fa-times"></i></button>
        <ul>
          <li v-for="performer in filteredPerformers" :key="performer.id">
            {{ performer.fio }}
            <span v-if="performer.phone"><b>Phone:</b> {{ performer.phone }} </span>
            <span v-if="performer.vk"><b>VK:</b> {{ performer.vk }} </span>
            <span v-if="performer.telega"> <b>Telegram:</b> {{ performer.telega }} </span>
            <span v-if="performer.whatsapp"> <b>WhatsApp:</b> {{ performer.whatsapp }} </span>
            <span v-if="performer.birzha"> <b>Birzha:</b> {{ performer.birzha }} </span>
            <span v-if="performer.comment"> <b>Comment:</b> {{ performer.comment }} </span>
            <span v-if="performer.subject"> <b>Список предметов:</b> {{ performer.subject }} </span>
            <button @click="editPerformer(performer)" class="edit-button"><i class="fas fa-pencil-alt"></i></button>
            <button @click="deletePerformer(performer.id)" class="delete-button"><i
                class="fas fa-trash-alt"></i></button>
            <hr>
            <div v-if="performer && performer.editMode1">
              <input type="text" placeholder="fio" v-model="performer.fio">
              <input type="text" placeholder="phone" v-model="performer.phone">
              <input type="text" placeholder="vk" v-model="performer.vk">
              <input type="text" placeholder="telega" v-model="performer.telega">
              <input type="text" placeholder="whatsapp" v-model="performer.whatsapp">
              <input type="text" placeholder="birza" v-model="performer.birzha">
              <input type="text" placeholder="comment" v-model="performer.comment">
              <textarea type="text" placeholder="Список предметов" v-model="performer.subject"></textarea>
              <button @click="updatePerformer(performer)">Сохранить</button>
              <button @click="cancelPerformer(performer)">Отменить</button>
            </div>

          </li>


        </ul>


      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '../config'; // Импортируем базовый URL

export default {
  name: 'AllLudi',
  data() {
    return {
      customers: [],
      performers: [],
      newCustomerFIO: '',
      newPerformerFIO: '',
      newCustomerVk: '',
      newCustomerTelega: '',
      newPerformerTelega: '',
      newPerformerBirzha: '',
      newPerformerWhatsapp: '',
      newPerformerSubject: '',
      newCustomerWhatsapp: '',
      newCustomerComment: '',
      editedCustomerId: null,
      editedPerformer: null,
      newCustomerFIO1: '',
      newCustomerVk1: '',
      newCustomerTelega1: '',
      newCustomerWhatsapp1: '',
      newCustomerBirzha1: '',
      newCustomerComment1: '',
      newPerformerSubject1: '',
      searchQuery: '',
      searchQuery1: '',

    };
  },
  methods: {
    clearSearch() {
      this.searchQuery = ''; // Очищаем значение поля поиска
    },
    clearSearch1() {
      this.searchQuery1 = ''; // Очищаем значение поля поиска
    },
    async fetchCustomers() {
      try {
        const token = localStorage.getItem('token'); // Получение токена из localStorage (предполагается, что вы его туда сохраняете при входе в систему)
        const response = await fetch(`${BASE_URL}/customers?sort=createdAt`, {
          headers: {
            'Authorization': `Bearer ${token}` // Добавление токена в заголовок Authorization
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        this.customers = await response.json();
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    },

    async fetchPerformers() {
  try {
    const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
    const response = await fetch(`${BASE_URL}/performers?sort=createdAt`, {
      headers: {
        'Authorization': `Bearer ${token}` // Включаем токен в заголовок запроса
      }
    });
    if (response.ok) {
      this.performers = await response.json();
    } else {
      console.error('Ошибка при получении списка исполнителей:', response.statusText);
    }
  } catch (error) {
    console.error('Ошибка при получении списка исполнителей:', error);
  }
},


    async addCustomer() {
      try {
        const token = localStorage.getItem('token'); // Получение токена из localStorage

        const response = await fetch(`${BASE_URL}/customers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавление токена в заголовок Authorization
          },
          body: JSON.stringify({
            fio: this.newCustomerFIO,
            vk: this.newCustomerVk,
            telegram: this.newCustomerTelega,
            whatsapp: this.newCustomerWhatsapp,
            birzha: this.newCustomerBirzha,
            comment: this.newCustomerComment
          })
        });

        if (!response.ok) {
          throw new Error('Failed to add customer');
        }

        const data = await response.json();
        console.log('Новый заказчик успешно добавлен:', data);

        // Добавляем нового заказчика в список
        this.customers.push({
          fio: this.newCustomerFIO,
          vk: this.newCustomerVk,
          telegram: this.newCustomerTelega,
          whatsapp: this.newCustomerWhatsapp,
          birzha: this.newCustomerBirzha,
          comment: this.newCustomerComment,
          editMode: false
        });

        // Очищаем поля ввода после успешного добавления
        this.clearInputFields();

        // Обновляем список заказчиков после успешного добавления
        this.fetchCustomers();
      } catch (error) {
        console.error('Ошибка при добавлении заказчика:', error);
      }
    },



    async addPerformer() {
      try {
        const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
        const response = await fetch(`${BASE_URL}/performers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` 
          },
          body: JSON.stringify({
            fio: this.newPerformerFIO,
            phone: this.newPerformerPhone,
            vk: this.newPerformerVk,
            telega: this.newPerformerTelega, // Добавлено свойство telega
            whatsapp: this.newPerformerWhatsapp,
            birzha: this.newPerformerBirzha,
            comment: this.newPerformerComment,
            subject: this.newPerformerSubject,
          })
        });
        const data = await response.json();
        console.log('Новый исполнитель успешно добавлен:', data);
        // Обновите список исполнителей после успешного добавления
        this.performers.push({
          fio: this.newPerformerFIO,
          phone: this.newPerformerPhone,
          vk: this.newPerformerVk,
          telega: this.newPerformerTelega,
          whatsapp: this.newPerformerWhatsapp,
          birzha: this.newPerformerBirzha,
          comment: this.newPerformerComment,
          editMode: false // Устанавливаем значение editMode1 для нового исполнителя
        });
        this.newPerformerFIO = ''
        this.newPerformerPhone = ''
        this.newPerformerVk = ''
        this.newPerformerTelega = ''
        this.newPerformerWhatsapp = ''
        this.newPerformerBirzha = ''
        this.newPerformerComment = ''
        this.fetchPerformers();
      } catch (error) {
        console.error('Ошибка при добавлении исполнителя:', error);
      }
    },
    editCustomer(customer) {
      // Проверяем, что объект customer не пустой и имеет свойство fio
      if (customer && customer.fio) {
        // Устанавливаем editMode в true для данного заказчика
        customer.editMode = true;
        // Перенести данные заказчика в поля ввода для редактирования
        this.newCustomerFIO1 = customer.fio;
        this.newCustomerVk1 = customer.vk;
        this.newCustomerTelega1 = customer.telegram;
        this.newCustomerWhatsapp1 = customer.whatsapp;
        this.newCustomerBirzha1 = customer.birzha;
        this.newCustomerComment1 = customer.comment;
      } else {
        console.error('Неверный формат объекта заказчика');
      }
    },
    async updateCustomer(customer) {
      try {
        const token = localStorage.getItem('token'); // Получение токена из localStorage

        const response = await fetch(`${BASE_URL}/customers/${customer.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавление токена в заголовок Authorization
          },
          body: JSON.stringify({
            fio: customer.fio,
            vk: customer.vk,
            telegram: customer.telegram,
            whatsapp: customer.whatsapp,
            birzha: customer.birzha,
            comment: customer.comment
          })
        });

        if (response.ok) {
          console.log('Профиль заказчика успешно обновлен');

          // Обновить список заказчиков после успешного обновления
          this.fetchCustomers();

          // Установить editMode обратно в false
          customer.editMode = false;
        } else {
          console.error('Ошибка при обновлении профиля заказчика:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при обновлении профиля заказчика:', error);
      }
    },



    async cancelCustomer(customerId) {
      customerId.editMode = false;
    },
    async deleteCustomer(customerId) {
      if (confirm('Вы уверены, что хотите удалить этого заказчика?')) {
        try {
          const response = await fetch(`${BASE_URL}/customers/${customerId}`, {
            method: 'DELETE'
          });
          if (response.ok) {
            console.log('Заказчик успешно удален');
            // Обновить список заказчиков после успешного удаления
            this.fetchCustomers();
          } else {
            console.error('Ошибка при удалении заказчика:', response.statusText);
          }
        } catch (error) {
          console.error('Ошибка при удалении заказчика:', error);
        }
      }
    },


    // performer


    // Добавьте методы для работы с исполнителями
    editPerformer(performer) {
  
      if (performer && performer.fio) {

        performer.editMode1 = true;

        console.log('3')
        this.newPerformerFIO1 = performer.fio
        this.newPerformerPhone1 = performer.phone
        this.newPerformerVk1 = performer.vk
        this.newPerformerTelega1 = performer.telega
        this.newPerformerWhatsapp1 = performer.whatsapp
        this.newPerformerBirzha1 = performer.birzha
        this.newPerformerComment1 = performer.comment
        this.newPerformerSubject1 = performer.subject
        // editMode: false // Устанавливаем editMode в false для нового заказчика
        // Перенести данные исполнителя в поля ввода для редактирования
        // Аналогично методу editCustomer
      } else {
        console.error('Неверный формат объекта исполнителя');
      }
    },
    async updatePerformer(performer) {
      try {
        const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
        const response = await fetch(`${BASE_URL}/performers/${performer.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Включаем токен в заголовок запроса
          },
          body: JSON.stringify({
            fio: performer.fio,
            vk: performer.vk,
            phone: performer.phone,
            telega: performer.telega,
            whatsapp: performer.whatsapp,
            comment: performer.comment,
            subject: performer.subject
          })
        });
        if (response.ok) {
          console.log('Профиль исполнителя успешно обновлен');
          this.fetchPerformers();
          // performer.editMode1 = false;
        } else {
          console.error('Ошибка при обновлении профиля исполнителя:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при обновлении профиля исполнителя:', error);
      }
    },

    async cancelPerformer(performer) {
      performer.editMode1 = false;
    },
    async deletePerformer(performerId) {
  if (confirm('Вы уверены, что хотите удалить этого исполнителя, если не удаляется значит есть заказы с этим исполнитем удалите все заказы с ним для удаления?')) {
    try {
      const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
      const response = await fetch(`${BASE_URL}/performers/${performerId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}` // Включаем токен в заголовок запроса
        }
      });
      if (response.ok) {
        console.log('Исполнитель успешно удален');
        this.fetchPerformers();
      } else {
        console.error('Ошибка при удалении исполнителя:', response.statusText);
      }
    } catch (error) {
      console.error('Ошибка при удалении исполнителя:', error);
    }
  }
},

    // Добавьте остальные методы для работы с исполнителями по аналогии с заказчиками




  },
  mounted() {
    this.fetchCustomers();
    this.fetchPerformers();
  },
  computed: {
    filteredPerformers() {
      const searchQuery = this.searchQuery.toLowerCase().trim(); // Используем searchQuery вместо performerSearchQuery
      if (!searchQuery) return this.performers; // Если запрос пустой, возвращаем всех исполнителей
      return this.performers.filter(performer => {
        // Преобразуем все свойства исполнителя в строку и приводим к нижнему регистру
        const performerString = JSON.stringify(performer).toLowerCase();
        // Проверяем, содержит ли строка исполнителя поисковой запрос
        return performerString.includes(searchQuery);
      });
    },

    filteredCustomers() {
      const searchQuery1 = this.searchQuery1.toLowerCase().trim();
      if (!searchQuery1) return this.customers;
      return this.customers.filter(customer => {
        const customerString = JSON.stringify(customer).toLowerCase();
        return customerString.includes(searchQuery1);
      });
    }
  }
};

</script>

<style scoped>
.container {
  display: flex;
  overflow-x: auto; /* Включаем горизонтальную прокрутку, если таблица шире контейнера */
    overflow-y: auto; 
}

ul li {
  list-style: none;
  text-align: left;
}

ul li span {
  padding: 0 3px;
}

.customers {
  flex: 1;
  margin-right: 20px;
}

.performers {
  flex: 1;
}

.search-container {
  position: relative;
}

.search-input {
  width: 100%;
  padding-right: 30px;
  /* Добавляем место для кнопки очистки */
}

.clear-button {

  /* top: 50%; */
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.clear-button i {
  color: #ccc;
}

.clear-button i:hover {
  color: #999;
}

form {
  padding-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

button.clear-button {
  background-color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

button.clear-button:hover {
  background-color: inherit;
}

.clear-button i {
  color: #beb9b9;
  position: relative;
  font-size: 16px;
  top: 9px;
  z-index: 1;
  right: 29px;
}

.edit-button,
.delete-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.clear-button i {
  color: #ccc;
}

.clear-button i:hover {
  color: #999;
}

.edit-button i,
.delete-button i {
  color: #007bff;
  /* Цвет ссылок, можно изменить на ваш */
}

.edit-button i:hover,
.delete-button i:hover {
  color: #0056b3;
  /* Цвет ссылок при наведении, можно изменить на ваш */
}
.edit-button, .delete-button{
  padding: 0 2px;
  margin: 0;
}
@media(max-width:780px){
  form div{
     display: flex;
    flex-direction: column;
  }
  textarea{
    width:  auto!important;;
  }
  .clear-button i {

    font-size: 13px;


}
 
}
</style>