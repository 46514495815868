<template>
    <div v-if="showOrderCreatedMessage" class="success-message">Ваша заявка успешно добавлена!</div>
  
    <div class="order-form">

        <div>
            <label>Исполнитель:</label>
            <input v-model="selectedPerformer" @input="searchPerformers" type="text" class="form-control"
                placeholder="Введите имя заказчика">
            <ul v-if="showPerformersList" class="performers-list">
                <li v-for="performer in filteredPerformers" :key="performer.id" @click="selectPerformer(performer)">
                    {{ performer.fio }}
                </li>
            </ul>
        </div>

        <div>
            <label>Заказчик:</label>
            <input v-model="selectedCustomer" @input="searchCustomer" type="text" class="form-control"
                placeholder="Введите имя исполнителя">
            <ul v-if="showCustomersList" class="performers-list">
                <li v-for="customer in filteredCustomers" :key="customer.id" @click="selectCustomer(customer)">
                    {{ customer.fio }}
                </li>
            </ul>
        </div>



        <div class="form-group">
            <label>Deadline:</label>
            <input type="datetime-local" v-model="deadline" class="form-control">
            <!-- <button @click="confirmDateTime">Подтвердить</button> -->
        </div>
        <div class="form-group">
            <label>Комментарий:</label><br />
            <textarea type="text" v-model="comment" class="form-control"></textarea>
        </div>
        <div class="form-group">
            <label>Откуда заказ:</label><br />
            <textarea type="text" v-model="otkuda" class="form-control"></textarea>
        </div>
        <div class="form-group">
            <label>Статус заказа:</label>
            <select v-model="selectedStatus"   class="form-control">
                <option v-for="status in orderStatuses" :key="status.id" :value="status.id">{{ status.name }}</option>
            </select>
        </div>
        <button @click="createOrder" class="btn btn-primary">Создать заказ</button>


        <!-- Таблица с данными заказов -->


        <div class="order-table">
            <p>Квадратик с цветом показывает статус дедлайна:</p>
            <ul class="kvadrat">
                <li>
                    <div class="square negative"></div> -
                </li>
                <li>
                    <div class="square positive"></div> +
                </li>
                <li>
                    <div class="square zero"></div> 0
                </li>
            </ul>
            <input type="text" v-model="searchQuery" placeholder="Поиск...">
            <button @click="clearSearch" class="clear-button"><i class="fas fa-times"></i></button>
            <div class="status_zakaza ">
                <button @click="showOrdersByStatus('Показать все')" class="status-button">
                    Показать все
                </button>
                <button @click="showOrdersByStatus('В работе')" class="status-button">
                    В работе
                </button>
                <button @click="showOrdersByStatus('Выполнен')" class="status-button">
                    Выполнен
                </button>
                <button @click="showOrdersByStatus('Подвел автор')" class="status-button">
                    Подвел автор
                </button>
                <button @click="showOrdersByStatus('Думает')" class="status-button">
                    Думает
                </button>
                <button @click="showOrdersByStatus('Ищем автора')" class="status-button">
                    Ищем автора
                </button>
                <button @click="showOrdersByStatus('Отменен')" class="status-button">
                    Отменен
                </button>
                <button @click="showOrdersByStatus('На доработке')" class="status-button">
                    На доработке
                </button>
            </div>
            <h2>Список заказов</h2>
            <div v-if="showOrderCreatedMessage1" class="success-message">Ваша заявка успешно удалена в корзину , оттуда можно ее востановить!</div>
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <td>№</td>
                            <th @click="sortBy('performer')">Исполнитель <i :class="sortIcon('performer')"></i></th>
                            <th @click="sortBy('customer')">Заказчик <i :class="sortIcon('customer')"></i></th>
                            <th @click="sortBy('deadline')">Deadline <i :class="sortIcon('deadline')"></i></th>
                            <th @click="sortBy('comment')">Комментарий <i :class="sortIcon('comment')"></i></th>
                            <th @click="sortBy('otkuda')">Откуда заказ <i :class="sortIcon('otkuda')"></i></th>
                            <th @click="sortBy('status')">Статус заказа <i :class="sortIcon('status')"></i></th>
                            <th @click="sortBy('daysRemaining')">Осталось <i :class="sortIcon('daysRemaining')"></i>
                            </th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(order, index) in filteredAndSortedOrders" :key="order.id"
                            :class="getStatusClass(order)">

                            <td>{{ index + 1 }}</td>
                            <td>
                                <template v-if="!order.isEditing">{{ order.performer ? order.performer.fio : '-'
                                    }}



                                    <button @click="showPerformerInfo(order.performer.id)">
                                        <i class="fas fa-eye"></i>
                                    </button>

                                </template>
                                <template v-else>
                                    <select v-model="order.performer.id" class="form-control">
                                        <option v-for="performer in performers" :key="performer.id"
                                            :value="performer.id">{{
        performer.fio }}</option>
                                    </select>
                                </template>
                            </td>
                            <td>
                                <template v-if="!order.isEditing">{{ order.customer ? order.customer.fio : '_' }}

                                    <button @click="showCustomerInfo(order.customer.id)">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </template>

                                <template v-else>
                                    <select v-model="order.customer" class="form-control">
                                        <option v-for="customer in customers" :key="customer.id" :value="customer">{{
        customer.fio }}</option>

                                    </select>
                                </template>
                            </td>
                            <td>
                                <template v-if="!order.isEditing">
                                    {{ formatDate(order.deadline) }}

                                </template>
                                <template v-else>
                                    <input v-model="order.deadline" type="datetime-local" v-if="order.deadline">
                                </template>
                            </td>
                            <td style="max-width: 250px;">
                                <template v-if="!order.isEditing">{{ order.comment }}</template>
                                <template v-else>
                                    <textarea v-model="order.comment"></textarea>
                                </template>
                            </td>
                            <td style="max-width: 250px;">
                                <template v-if="!order.isEditing">{{ order.otkuda }}</template>
                                <template v-else>
                                    <textarea v-model="order.otkuda"></textarea>
                                </template>
                            </td>
                            <td>
                                <template v-if="!order.isEditing">
                                    {{ order.status ? order.status.name : '_' }}
                                </template>
                                <template v-else>
                                    <select v-model="order.selectedStatus" class="form-control">
                                        <option v-for="status in orderStatuses" :key="status.id" :value="status.id">{{
        status.name }}</option>
                                    </select>
                                </template>
                            </td>
                            <td>
                                {{ formatDateTime(order.deadline) }}
                                <span v-if="order.daysRemaining !== undefined">
                                    <!-- Выводим информацию о количестве дней -->
                                    {{ order.daysRemaining }} дн.
                                </span>
                            </td>
                            <td>
                                <template v-if="!order.isEditing">
                                    <button @click="editOrder(order)">Редактировать</button>
                                </template>
                                <template v-if="order.isEditing">
                                    <button @click="saveOrder(order)">Сохранить</button>
                                    <button @click="cancelEdit(order)">Отменить</button>
                                </template>
                                <button @click="deleteOrder(order)">Удалить</button>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>


        <!-- Модальное окно для отображения информации о заказчике -->
        <div id="customerModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <h2>Информация </h2>
                <ul class="info">
                    <li v-for="(value, key) in getFilteredCustomerInfo(customerInfo)" :key="key">
                        <b>{{ key }}:</b> {{ value }}
                    </li>
                </ul>
                <ul class="info">
                    <li v-for="(value, key) in performerInfo" :key="key">
                        <b>{{ key }}:</b> {{ value }}
                    </li>
                </ul>

            </div>
        </div>

    </div>






</template>

<script>
import { BASE_URL } from '../config.js'; // Импортируем базовый URL
import '@fortawesome/fontawesome-free/css/all.css';


export default {
    name: 'ZakazAll',
    data() {
        return {
            showOrderCreatedMessage: false,
            showOrderCreatedMessage1: false,
            allOrders: [],
            performers: [],
            customers: [],
            orderStatuses: [],
            selectedPerformer: null,
            selectedCustomer: null,
            selectedCustomerId: null,
            selectedPerformerId: null,
            deadline: '',
            comment: '',
            otkuda: '',
            selectedStatus: null,
            orders: [], // Массив для хранения загруженных заказов
            isEditing: false,
            editedOrder: {},
            newCustomer: '',
            newPerformer: '',

            searchQuery: '', // Строка для хранения поискового запроса
            showPerformersList: false, // показать выпадающий список
            filteredPerformers: [],// отфильтрованный список исполнителей

            customerInfo: null,
            performerInfo: null,

            showCustomersList: false,
            filteredCustomers: [],
            sortByKey: '', // Ключ для сортировки
            sortDesc: false, // По умолчанию сортировка по возрастани
            sortByColumn: '', // Переменная для хранения названия столбца, по которому происходит сортировка
            sortDirection: 'asc', // Переменная для хранения направления сортировки (asc - по возрастанию, desc - по убыванию)

        };
    },
    mounted() {
        this.fetchPerformers();
        this.fetchCustomers();
        this.fetchOrderStatuses();
        this.fetchOrders();
    },
    computed: {



        // Вычисляемое свойство для фильтрации заказов
        filteredOrders() {
            return this.orders.filter(order => {
                // Преобразуем весь заказ в строку и приводим к нижнему регистру
                const orderString = JSON.stringify(order).toLowerCase();
                // Приводим поисковой запрос к нижнему регистру
                const query = this.searchQuery.toLowerCase();
                // Проверяем, содержит ли строка заказа поисковой запрос
                return orderString.includes(query);
            });
        },
        // Вычисляемое свойство для отсортированных заказов
        sortedOrders() {
            if (this.sortByColumn) {
                // Клонируем массив заказов для избежания мутации состояния
                let sortedOrders = [...this.orders];
                // Сортируем заказы по выбранному столбцу и направлению сортировки
                sortedOrders.sort((a, b) => {
                    let valA = a[this.sortByColumn] !== undefined ? a[this.sortByColumn].toString().toLowerCase() : '';
                    let valB = b[this.sortByColumn] !== undefined ? b[this.sortByColumn].toString().toLowerCase() : '';

                    // Проверяем, является ли значение числом
                    if (!isNaN(valA) && !isNaN(valB)) {
                        return (this.sortDirection === 'asc' ? valA - valB : valB - valA);
                    } else {
                        // Иначе сравниваем значения как строки
                        return (this.sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA));
                    }
                });
                return sortedOrders;
            }
            // Если столбец для сортировки не выбран, возвращаем исходный массив заказов
            return this.orders;
        },
        // поиск
        filteredAndSortedOrders() {
            if (!this.searchQuery) {
                return this.sortedOrders;
            } else {
                const query = this.searchQuery.toLowerCase();
                return this.sortedOrders.filter(order => {
                    // Проверяем, что order.id является строкой, иначе преобразуем его в строку
                    const orderId = order.id.toString();
                    return (
                        orderId.toLowerCase().includes(query) ||
                        (order.performer && order.performer.fio.toLowerCase().includes(query)) ||
                        (order.customer && order.customer.fio.toLowerCase().includes(query)) ||
                        order.comment.toLowerCase().includes(query) ||
                        order.otkuda.toLowerCase().includes(query)
                    );
                });
            }
        }

    },
    methods: {
        confirmDateTime() {
        // Здесь вы можете выполнить действия, связанные с выбором даты и времени
        alert('Выбранная дата и время: ' + this.deadline);
      },
        getFilteredCustomerInfo(customerInfo) {
            const filteredInfo = {};
            for (const key in customerInfo) {
                if (key !== 'id' && key !== 'adminId' && key !== 'createdAt' && customerInfo[key] !== null && customerInfo[key] !== '') {
                    filteredInfo[key] = customerInfo[key];
                }
            }
            return filteredInfo;
        },
        // Обработчик события для элемента заказчика
        showCustomerInfo(customerId) {
            // Получение токена из локального хранилища
            const token = localStorage.getItem('token');

            // Проверка наличия токена
            if (!token) {
                console.error('Токен не найден');
                return;
            }

            // Заголовок запроса с токеном
            const headers = {
                'Authorization': `Bearer ${token}`
            };

            // Опции запроса
            const requestOptions = {
                method: 'GET',
                headers: headers
            };

            // Отправка AJAX-запроса на сервер для получения информации о заказчике
            fetch(`${BASE_URL}/customers/${customerId}`, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Ошибка при получении информации о заказчике');
                    }
                    return response.json();
                })
                .then(customerInfo => {
                    // Отображение информации о заказчике, например, в модальном окне
                    this.showModal(customerInfo);
                })
                .catch(error => {
                    console.error('Ошибка при получении информации о заказчике:', error);
                });
        },


        // Обработчик события для элемента исполнителя
        showPerformerInfo(performerId) {
            // Получение токена из локального хранилища
            const token = localStorage.getItem('token');

            // Проверка наличия токена
            if (!token) {
                console.error('Токен не найден');
                return;
            }

            // Заголовок запроса с токеном
            const headers = {
                'Authorization': `Bearer ${token}`
            };

            // Опции запроса
            const requestOptions = {
                method: 'GET',
                headers: headers
            };

            // Отправка AJAX-запроса на сервер для получения информации об исполнителе
            fetch(`${BASE_URL}/performers/${performerId}`, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Ошибка при получении информации об исполнителе');
                    }
                    return response.json();
                })
                .then(performerInfo => {
                    // Отображение информации об исполнителе, например, в модальном окне
                    this.showModal(performerInfo);
                })
                .catch(error => {
                    console.error('Ошибка при получении информации об исполнителе:', error);
                });
        },

        // модалка
        showModal(customerInfo) {
            this.customerInfo = customerInfo;
            const modal = document.getElementById('customerModal');
            modal.style.display = 'block';
        },
        closeModal() {
            const modal = document.getElementById('customerModal');
            modal.style.display = 'none';
            this.customerInfo = null;
        },




        clearSearch() {
            this.searchQuery = ''; // Очищаем значение поля поиска
        },
        // Метод для сортировки
        sortBy(column) {
            // Если столбец, по которому происходит сортировка, совпадает с текущим столбцом, меняем направление сортировки
            console.log('Column:', column); // Отладочная информация
            console.log('Current SortByColumn:', this.sortByColumn); // Отладочная информация
            console.log('Current SortDirection:', this.sortDirection); // Отладочная информация
            if (this.sortByColumn === column) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                // Если столбец изменился, устанавливаем новый столбец сортировки и сортировку по возрастанию
                this.sortByColumn = column;
                this.sortDirection = 'asc';
            }
            console.log('New SortByColumn:', this.sortByColumn); // Отладочная информация
            console.log('New SortDirection:', this.sortDirection); // Отладочная информация
        },

        // Метод для определения класса иконки сортировки
        sortIcon(column) {
            if (column !== this.sortByColumn) {
                return '';
            }

            return this.sortDirection === 'asc' ? 'fas fa-sort-up' : 'fas fa-sort-down';
        },
        formatDateTime(dateTimeString) {
            const deadline = new Date(dateTimeString);
            const today = new Date();
            const diffTime = deadline - today;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Разница в днях

            return diffDays; // Возвращаем количество дней до/после дедлайна
        },
        getStatusClass(order) {
            const diffDays = this.formatDateTime(order.deadline);
            console.log('цвет', diffDays)
            // Определяем класс CSS в зависимости от количества дней до/после дедлайна
            if (diffDays < 0) {
                return 'negative'; // Класс для строк с отрицательным количеством дней
            } else if (diffDays > 1) {
                return 'positive'; // Класс для строк с положительным количеством дней
            } else {
                return 'zero'; // Класс для строк с нулевым количеством дней
            }
        },
        showOrdersByStatus(status) {
            if (status === 'Показать все') {
                // Показать все заказы из временного хранилища
                this.orders = this.allOrders.filter(order => order.status.name !== 'Отменен' && order.status.name !== 'Подвел автор');
            } else if (status === 'В работе') {
                // Фильтрация заказов по статусу "В работе"
                this.orders = this.allOrders.filter(order => order.status.name === 'В работе');
            } else if (status === 'Выполнен') {
                // Фильтрация заказов по статусу "выполнен"
                this.orders = this.allOrders.filter(order => order.status.name === 'Выполнен');
            } else if (status === 'Отменен') {
                // Фильтрация заказов по статусу "Отменен"
                this.orders = this.allOrders.filter(order => order.status.name === 'Отменен');
            } else if (status === 'Думает') {
                // Фильтрация заказов по статусу "Думает"
                this.orders = this.allOrders.filter(order => order.status.name === 'Думает');
            } else if (status === 'Ищем автора') {
                // Фильтрация заказов по статусу "Ищем автора"
                this.orders = this.allOrders.filter(order => order.status.name === 'Ищем автора');
            } else if (status === 'Подвел автор') {
                // Фильтрация заказов по статусу "Подвел автор"
                this.orders = this.allOrders.filter(order => order.status.name === 'Подвел автор');
            } else if (status === 'На доработке') {
                // Фильтрация заказов по статусу "На доработке"
                this.orders = this.allOrders.filter(order => order.status.name === 'На доработке');
            } else {
                // Показать все заказы, кроме тех, которые имеют статус "Отменен" и "Подвел автор"
                this.orders = this.allOrders.filter(order => order.status.name !== 'Отменен' && order.status.name !== 'Подвел автор');
            }
        },


        clearPerformer() {
            this.selectedPerformer = null; // Очистить выбранного исполнителя
        },
        clearCustomer() {
            this.selectedCustomer = null; // Очистить выбранного заказчика
        },


        // Если заказ в режиме редактирования, сохраняем его для возможного отмены редактирования
        editOrder(order) {
            order.isEditing = !order.isEditing;

            // Если заказ в режиме редактирования, сохраняем его для возможного отмены редактирования
            if (order.isEditing) {
                this.editedOrder = { ...order }; // Сохраняем копию заказа
                order.selectedStatus = order.status ? order.status.id : null;
                // Форматируем дату заказа для отображения в элементе управления <input type="datetime-local">
                const orderDateTime = new Date(order.deadline); // Предполагаем, что deadline представляет собой корректную дату
                const formattedDateTime = `${orderDateTime.getFullYear()}-${String(orderDateTime.getMonth() + 1).padStart(2, '0')}-${String(orderDateTime.getDate()).padStart(2, '0')}T${String(orderDateTime.getHours()).padStart(2, '0')}:${String(orderDateTime.getMinutes()).padStart(2, '0')}`;
                order.deadline = formattedDateTime; // Устанавливаем отформатированную дату и время для редактирования

                // Проверяем, существует ли исполнитель, и если нет, устанавливаем его в значение null
                if (!order.status) {
                    order.status = { id: null }; // Устанавливаем фиктивный объект исполнителя с id null
                }

                if (!order.performer) {
                    order.performer = { id: null }; // Устанавливаем фиктивный объект исполнителя с id null
                }

                // Проверяем, существует ли заказчик, и если нет, устанавливаем его в значение null
                if (!order.customer) {
                    order.customer = { id: null }; // Устанавливаем фиктивный объект заказчика с id null
                }
            }
        }
        ,
        saveOrder(order) {
            console.log('saveOrder');
            // Логика для сохранения отредактированного заказа
            order.isEditing = false;
            // Получаем токен из localStorage (или из другого места, где он хранится)
            const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
            // Проверяем, изменился ли статус заказа
            if (order.selectedStatus !== order.status?.id) {
                // Отправляем запрос на обновление статуса заказа
                fetch(`${BASE_URL}/orders/${order.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ status: order.selectedStatus }),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Ошибка при обновлении статуса заказа');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log('Статус заказа успешно обновлен:', data);
                        // Обновляем список заказов
                        this.fetchOrders();
                    })
                    .catch(error => {
                        console.error('Ошибка при обновлении статуса заказа:', error);
                    });
            } else {
                // Если статус заказа не изменился, просто отправляем данные на сервер без обновления статуса
                fetch(`${BASE_URL}/orders/${order.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(order),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Ошибка при сохранении заказа');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log('Заказ успешно обновлен:', data);
                        // Обновляем список заказов
                        this.fetchOrders();
                    })
                    .catch(error => {
                        console.error('Ошибка при сохранении заказа:', error);
                    });
            }
        },

        cancelEdit(order) {
            order.isEditing = false;
        },

        // async deleteOrder(order) {
        //     const confirmDelete = confirm('Вы уверены, что хотите удалить этот заказ?');
        //     if (confirmDelete) {
        //         try {
        //             const token = localStorage.getItem('token');
        //             // Отправляем запрос на удаление заказа по его ID
        //             await fetch(`${BASE_URL}/orders/${order.id}`, {
        //                 method: 'DELETE',
        //                 headers: {
        //                     'Authorization': `Bearer ${token}` // Добавляем токен к заголовкам запроса
        //                 }

        //             });
        //             // Успешно удаляем заказ из списка заказов
        //             this.orders = this.orders.filter(item => item.id !== order.id);
        //         } catch (error) {
        //             console.error('Ошибка при удалении заказа:', error);
        //         }
        //     }
        // },

        async deleteOrder(order) {
            const confirmDelete = confirm('Вы уверены, что хотите удалить этот заказ?');
            if (confirmDelete) {
                try {
                    const token = localStorage.getItem('token');
                    // Отправляем запрос на удаление заказа по его ID
                    await fetch(`${BASE_URL}/orders/${order.id}/delete`, {
                        method: 'PUT', // Используем метод PUT для обновления ресурса
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}` // Добавляем токен к заголовкам запроса
                        },
                        body: JSON.stringify(order) // Отправляем тело запроса с данными для обновления
                    });
                    // Успешно удаляем заказ из списка заказов
                    this.orders = this.orders.filter(item => item.id !== order.id);
                    this.showOrderCreatedMessage1 =true;
                    setTimeout(() => {
                        this.showOrderCreatedMessage1 = false;
                    }, 3000);

                } catch (error) {
                    console.error('Ошибка при удалении заказа:', error);
                }
            }
        },


        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString(); // Используйте любой формат, который вам удобен
        },
        async fetchPerformers() {
            try {
                // Получение токена из локального хранилища
                const token = localStorage.getItem('token');

                // Проверка наличия токена
                if (!token) {
                    console.error('Токен не найден');
                    return;
                }

                // Заголовок запроса с токеном
                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                // Опции запроса
                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };

                // Отправка AJAX-запроса на сервер для получения списка исполнителей
                const response = await fetch(`${BASE_URL}/performers`, requestOptions);
                const data = await response.json();
                this.performers = data;
            } catch (error) {
                console.error('Ошибка при загрузке исполнителей:', error);
            }
        },

        async fetchOrders() {
            try {
                const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
                const response = await fetch(`${BASE_URL}/orders`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Добавляем токен к заголовкам запроса
                    }
                });
                const data = await response.json();
                this.orders = data;
                // Сохраняем все заказы во временном хранилище
                this.allOrders = [...data];
                this.orders = data;
                console.log("this.orders)", this.orders)
            } catch (error) {
                console.error('Ошибка при загрузке заказов:', error);
            }
        },

        async fetchCustomers() {
            try {
                // Получение токена из локального хранилища
                const token = localStorage.getItem('token');

                // Проверка наличия токена
                if (!token) {
                    console.error('Токен не найден');
                    return;
                }

                // Заголовок запроса с токеном
                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                // Опции запроса
                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };

                // Отправка AJAX-запроса на сервер для получения списка заказчиков
                const response = await fetch(`${BASE_URL}/customers`, requestOptions);
                const data = await response.json();
                this.customers = data;
            } catch (error) {
                console.error('Ошибка при загрузке заказчиков:', error);
            }
        },

        async fetchOrderStatuses() {
            try {
                const response = await fetch(`${BASE_URL}/order-statuses`);
                const data = await response.json();
                this.orderStatuses = data;
            } catch (error) {
                console.error('Ошибка при загрузке статусов заказа:', error);
            }
        },



        searchPerformers() {
            // Проверяем, был ли введен текст в поле поиска
            if (this.selectedPerformer === '') {
                this.filteredPerformers = [];
                this.showPerformersList = false;
                return;
            }

            try {
                // Получаем токен из localStorage
                const token = localStorage.getItem('token');

                // Проверяем, присутствует ли токен
                if (!token) {
                    console.error('Token not found');
                    return;
                }

                // Формируем заголовок Authorization
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };

                // Опции запроса
                const requestOptions = {
                    method: 'GET',
                    headers: headers
                };

                // Выполняем запрос на сервер для получения всех исполнителей
                fetch(`${BASE_URL}/performers`, requestOptions)
                    .then(response => {
                        // Проверяем статус ответа
                        if (!response.ok) {
                            throw new Error(`Request failed with status ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(performers => {
                        // Сохраняем полученных исполнителей в локальное хранилище
                        localStorage.setItem('performers', JSON.stringify(performers));

                        // Фильтруем исполнителей по введенному тексту
                        this.filteredPerformers = performers.filter(performer =>
                            performer.fio.toLowerCase().includes(this.selectedPerformer.toLowerCase())
                        );

                        // Показываем выпадающий список, если найдены исполнители
                        this.showPerformersList = this.filteredPerformers.length > 0;
                    })
                    .catch(error => {
                        console.error('Ошибка при поиске исполнителей:', error);
                    });
            } catch (error) {
                console.error('Ошибка при поиске исполнителей:', error);
            }
        },

        selectPerformer(performer) {
            this.selectedPerformer = performer.fio;
            this.selectedPerformerId = performer.id
            this.showPerformersList = false;
        },


        searchCustomer() {
            // Проверяем, был ли введен текст в поле поиска
            if (!this.selectedCustomer || this.selectedCustomer === '') {
                this.filteredCustomers = [];
                this.showCustomersList = false;
                return;
            }

            // Получаем токен из localStorage
            const token = localStorage.getItem('token');

            // Проверяем, присутствует ли токен
            if (!token) {
                console.error('Token not found');
                return;
            }

            // Формируем заголовок Authorization
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            };

            // Опции запроса
            const requestOptions = {
                method: 'GET',
                headers: headers
            };

            // Выполняем запрос на сервер для получения всех заказчиков
            fetch(`${BASE_URL}/customers`, requestOptions)
                .then(response => {
                    // Проверяем статус ответа
                    if (!response.ok) {
                        throw new Error(`Request failed with status ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    // Сохраняем полученных заказчиков в this.customers
                    this.customers = data;

                    // Фильтруем заказчиков по введенному тексту
                    this.filteredCustomers = this.customers.filter(customer => {
                        // Проверяем, содержит ли имя заказчика введенный текст (без учета регистра)
                        return customer.fio.toLowerCase().includes(this.selectedCustomer.toLowerCase());
                    });

                    // Проверяем, был ли найден хотя бы один заказчик после фильтрации
                    if (this.filteredCustomers.length > 0) {
                        // Если был найден хотя бы один заказчик, показываем выпадающий список
                        this.showCustomersList = true;
                    } else {
                        // Если не было найдено ни одного заказчика, скрываем выпадающий список
                        this.showCustomersList = false;
                    }
                })
                .catch(error => {
                    console.error('Ошибка при получении заказчиков:', error);
                });
        },






        selectCustomer(customer) {
            this.selectedCustomer = customer.fio;
            this.selectedCustomerId = customer.id;
            this.showCustomersList = false;
        },



        // async createOrder() {
        //     let customerId = null;
        //     let performerId = null;
        //     console.log('Before condition - selectedCustomerId:', this.selectedCustomerId);
        //     console.log('Before condition - newCustomer trimmed:', this.newCustomer.trim());
        //     // Проверяем, был ли введен новый заказчик
        //     if (this.selectedCustomerId) {
        //         customerId = this.selectedCustomerId;
        //     } else {
        //         // Если не был выбран существующий заказчик, создаем нового на основе введенного текста
        //         if (this.selectedCustomer && this.selectedCustomer.trim() !== '') {


        //             const newCustomerData = {
        //                 fio: this.selectedCustomer.trim()
        //                 // Другие поля заказчика, если нужно
        //             };

        //             // Отправляем запрос на создание нового заказчика
        //             try {
        //                 const response = await fetch(`${BASE_URL}/customers`, {
        //                     method: 'POST',
        //                     headers: {
        //                         'Content-Type': 'application/json',
        //                     },
        //                     body: JSON.stringify(newCustomerData),
        //                 });
        //                 const data = await response.json();
        //                 customerId = data.id;
        //             } catch (error) {
        //                 console.error('Ошибка при создании нового заказчика:', error);
        //                 return; // Выходим из метода, так как не удалось создать нового заказчика
        //             }
        //         }
        //     }

        //     if (this.selectedPerformerId) {
        //         performerId = this.selectedPerformerId;
        //     } else {
        //         // Если не был выбран существующий исполнитель, создаем нового на основе введенного текста
        //         if (this.selectedPerformer && this.selectedPerformer.trim() !== '') {
        //             const newPerformerData = {
        //                 fio: this.selectedPerformer.trim()
        //                 // Другие поля исполнителя, если нужно
        //             };

        //             // Отправляем запрос на создание нового исполнителя
        //             try {
        //                 const response = await fetch(`${BASE_URL}/performers`, {
        //                     method: 'POST',
        //                     headers: {
        //                         'Content-Type': 'application/json',

        //                     },
        //                     body: JSON.stringify(newPerformerData),
        //                 });
        //                 const data = await response.json();
        //                 performerId = data.id;
        //             } catch (error) {
        //                 console.error('Ошибка при создании нового исполнителя:', error);
        //                 return; // Выходим из метода, так как не удалось создать нового исполнителя
        //             }
        //         }
        //     }

        //     // Создаем заказ, используя ID новых заказчика и исполнителя, если они были введены
        //     const orderData = {
        //         performer: performerId,
        //         customer: customerId,
        //         deadline: this.deadline,
        //         comment: this.comment,
        //         otkuda: this.otkuda,
        //         status: this.selectedStatus,
        //         // Остальные данные заказа
        //     };

        //     // Отправляем запрос на создание заказа
        //     fetch(`${BASE_URL}/orders`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(orderData),
        //     })
        //         .then(response => {
        //             if (!response.ok) {
        //                 throw new Error('Ошибка при создании заказа');
        //             }
        //             return response.json();
        //         })
        //         .then(data => {
        //             console.log('Заказ успешно создан:', data);
        //             this.fetchOrders();
        //             this.fetchPerformers();
        //             this.fetchCustomers();
        //         })
        //         .catch(error => {
        //             console.error('Ошибка при создании заказа:', error);
        //         });
        // },

        async createOrder() {
            let customerId = null;
            let performerId = null;
            if (this.selectedStatus === null) {
          alert('Пожалуйста, выберите статус'); // или другой способ сообщения об ошибке
          return; // прерываем выполнение метода, чтобы форма не отправлялась
        }

            // Получаем токен из localStorage
            const token = localStorage.getItem('token');

            // Проверяем, был ли введен новый заказчик
            if (this.selectedCustomerId) {
                customerId = this.selectedCustomerId;
            } else {
                // Если не был выбран существующий заказчик, создаем нового на основе введенного текста
                if (this.selectedCustomer && this.selectedCustomer.trim() !== '') {
                    const newCustomerData = {
                        fio: this.selectedCustomer.trim()
                        // Другие поля заказчика, если нужно
                    };

                    // Отправляем запрос на создание нового заказчика с использованием токена
                    try {
                        const response = await fetch(`${BASE_URL}/customers`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify(newCustomerData),
                        });
                        const data = await response.json();
                        customerId = data.id;
                    } catch (error) {
                        console.error('Ошибка при создании нового заказчика:', error);
                        return; // Выходим из метода, так как не удалось создать нового заказчика
                    }
                }
            }

            if (this.selectedPerformerId) {
                performerId = this.selectedPerformerId;
            } else {
                // Если не был выбран существующий исполнитель, создаем нового на основе введенного текста
                if (this.selectedPerformer && this.selectedPerformer.trim() !== '') {
                    const newPerformerData = {
                        fio: this.selectedPerformer.trim()
                        // Другие поля исполнителя, если нужно
                    };

                    // Отправляем запрос на создание нового исполнителя с использованием токена
                    try {
                        const response = await fetch(`${BASE_URL}/performers`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify(newPerformerData),
                        });
                        const data = await response.json();
                        performerId = data.id;

                    } catch (error) {
                        console.error('Ошибка при создании нового исполнителя:', error);
                        return; // Выходим из метода, так как не удалось создать нового исполнителя
                    }
                }
            }

            // Создаем заказ, используя ID новых заказчика и исполнителя, если они были введены
            const orderData = {
                performer: performerId,
                customer: customerId,
                deadline: this.deadline,
                comment: this.comment,
                otkuda: this.otkuda,
                status: this.selectedStatus,
                // Остальные данные заказа
            };

            // Отправляем запрос на создание заказа с использованием токена
            try {
                const response = await fetch(`${BASE_URL}/orders`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(orderData),
                });
                const data = await response.json();
                console.log('Заказ успешно создан:', data);
                this.fetchOrders();
                this.fetchPerformers();
                this.fetchCustomers();
                this.selectedCustomerId = null;
                this.selectedPerformerId = null;
                this.selectedCustomer = '';
                this.selectedPerformer = '';
                this.deadline = '';
                this.comment = '';
                this.otkuda = '';
                this.selectedStatus = null;

                this.showOrderCreatedMessage = true;

                // Через некоторое время скрываем сообщение о создании заказа
                setTimeout(() => {
                    this.showOrderCreatedMessage = false;
                }, 5000);
            } catch (error) {
                console.error('Ошибка при создании заказа:', error);
            }
        }

        // тест
        // async createOrder() {
        //     let customerId = null;
        //     let performerId = null;

        //     // Проверяем, был ли введен новый заказчик
        //     if (this.selectedCustomerId) {
        //         customerId = this.selectedCustomerId;
        //     } else {
        //         // Если не был выбран существующий заказчик, ищем пользователя с указанным именем в базе данных
        //         if (this.selectedCustomer && this.selectedCustomer.trim() !== '') {
        //             try {
        //                 // Выполняем запрос к базе данных для поиска пользователя с указанным именем
        //                 const response = await fetch(`${BASE_URL}/customers?fio=${encodeURIComponent(this.selectedCustomer.trim())}`);
        //                 const data = await response.json();

        //                 // Если найден пользователь с указанным именем, берем его айди
        //                 if (data && data.length > 0) {
        //                     // Проверяем каждого пользователя на совпадение имени
        //                     const foundCustomer = data.find(customer => customer.fio === this.selectedCustomer.trim());
        //                     if (foundCustomer) {
        //                         customerId = foundCustomer.id;
        //                         console.log('customerId', customerId);
        //                     }
        //                 } else {
        //                     // Если пользователь не найден, создаем нового
        //                     const newCustomerData = {
        //                         fio: this.selectedCustomer.trim()
        //                         // Другие поля заказчика, если нужно
        //                     };

        //                     // Отправляем запрос на создание нового заказчика
        //                     const createResponse = await fetch(`${BASE_URL}/customers`, {
        //                         method: 'POST',
        //                         headers: {
        //                             'Content-Type': 'application/json',
        //                         },
        //                         body: JSON.stringify(newCustomerData),
        //                     });

        //                     const createData = await createResponse.json();
        //                     customerId = createData.id;
        //                 }
        //             } catch (error) {
        //                 console.error('Ошибка при создании/поиске заказчика:', error);
        //                 return; // Выходим из метода, так как не удалось создать или найти заказчика
        //             }
        //         }
        //     }

        //     // Проверяем, был ли введен новый исполнитель
        //     if (this.selectedPerformerId) {
        //         performerId = this.selectedPerformerId;
        //     } else {
        //         // Если не был выбран существующий исполнитель, ищем пользователя с указанным именем в базе данных
        //         if (this.selectedPerformer && this.selectedPerformer.trim() !== '') {
        //             try {
        //                 // Выполняем запрос к базе данных для поиска исполнителя с указанным именем
        //                 const response = await fetch(`${BASE_URL}/performers?fio=${encodeURIComponent(this.selectedPerformer.trim())}`);
        //                 const data = await response.json();

        //                 // Если найден исполнитель с указанным именем, берем его айди
        //                 if (data && data.length > 0) {
        //                     // Проверяем каждого исполнителя на совпадение имени
        //                     const foundPerformer = data.find(performer => performer.fio === this.selectedPerformer.trim());
        //                     if (foundPerformer) {
        //                         performerId = foundPerformer.id;
        //                         console.log('performerId', performerId);
        //                     }
        //                 } else {
        //                     // Если исполнитель не найден, создаем нового
        //                     const newPerformerData = {
        //                         fio: this.selectedPerformer.trim()
        //                         // Другие поля исполнителя, если нужно
        //                     };

        //                     // Отправляем запрос на создание нового исполнителя
        //                     const createResponse = await fetch(`${BASE_URL}/performers`, {
        //                         method: 'POST',
        //                         headers: {
        //                             'Content-Type': 'application/json',
        //                         },
        //                         body: JSON.stringify(newPerformerData),
        //                     });

        //                     const createData = await createResponse.json();
        //                     performerId = createData.id;
        //                 }
        //             } catch (error) {
        //                 console.error('Ошибка при создании/поиске исполнителя:', error);
        //                 return; // Выходим из метода, так как не удалось создать или найти исполнителя
        //             }
        //         }
        //     }

        //     // Создаем заказ, используя ID новых заказчика и исполнителя, если они были введены
        //     const orderData = {
        //         performer: performerId,
        //         customer: customerId,
        //         deadline: this.deadline,
        //         comment: this.comment,
        //         otkuda: this.otkuda,
        //         status: this.selectedStatus,
        //         // Остальные данные заказа
        //     };

        //     // Отправляем запрос на создание заказа
        //     fetch(`${BASE_URL}/orders`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(orderData),
        //     })
        //         .then(response => {
        //             if (!response.ok) {
        //                 throw new Error('Ошибка при создании заказа');
        //             }
        //             return response.json();
        //         })
        //         .then(data => {
        //             console.log('Заказ успешно создан:', data);
        //             this.fetchOrders();
        //             this.fetchPerformers();
        //             this.fetchCustomers();
        //         })
        //         .catch(error => {
        //             console.error('Ошибка при создании заказа:', error);
        //         });
        // }

    }


};
</script>

<style scoped>
body {
    margin: 0 auto;
}
.table-container, .status_zakaza {
    /* padding: 0 5px; */
    overflow-x: auto; /* Включаем горизонтальную прокрутку, если таблица шире контейнера */
    overflow-y: auto; /* Включаем вертикальную прокрутку, если таблица выходит за пределы контейнера */
    /* max-height: 300px; Например, устанавливаем максимальную высоту контейнера */
}
.order-form {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.form-group {
    width: calc(100%/4);
}

label {
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.btn {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn:hover {
    background-color: #0056b3;
}

.order-table {
    margin-top: 20px;
}

.order-table table {
    width: 100%;
    border-collapse: collapse;
}

.order-table th,
.order-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.order-table th {
    background-color: #f2f2f2;
}

/* 
.order-table tr:nth-child(even) {
    background-color: #f2f2f2;
} */

.order-table tr:hover {
    background-color: #ddd;
}

.order-table button {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
}

.order-table button:hover {
    background-color: #0056b3;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper .clear-button {
    position: absolute;
    top: 30%;
    right: -39px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.select-wrapper .clear-button i {
    color: #ccc;
    font-size: 18px;
}

.select-wrapper .clear-button i:hover {
    color: #999;
}

.status_zakaza {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.status-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

.status-button:hover {
    background-color: #0056b3;
}

.negative {
    background-color: #FFCCCC;
    /* Светло-красный для отрицательного количества дней */
}

.positive {
    background-color: #CCFFCC;
    /* Светло-зеленый для положительного количества дней */
}

.zero {
    background-color: #FFFFCC;
    /* Светло-желтый для нулевого количества дней */
}

.square {
    width: 20px!important;
    height: 20px;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #000;
}

.kvadrat {
    display: flex;


}

ul li {
    list-style: none;
    padding: 0 2px;
    cursor: pointer;
    color: #0a0a0a;
    font-weight: 800;
}

.kvadrat li {
    cursor: none;
    display: flex;
    align-items: center;
    font-weight: 400;
}



button.clear-button {
    background-color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

button.clear-button:hover {
    background-color: inherit;
}

.clear-button i {
    color: #beb9b9;
    position: relative;
    font-size: 16px;
    top: 3px;
    z-index: 1;
    right: 29px;
}

/* модалка */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 500px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.info li {
    cursor: none;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    margin-bottom: 10px;
}

@media(max-width:768px) {
    /* .status_zakaza{
        display: none;
    } */
    .order-form {
        flex-direction: column;

    }

    .order-form div {
        width: 98%;
    }

    .order-form select {
        margin-left: 0;
    }

    .order-form textarea {
        height: 65px;
    }
    /* .order-form{
        widows: 95%;
    } */
   /* .form-control[data-v-33eabd34], .btn {
    
    max-width: 500px;
}  */
input, textarea{
    margin: 0;
}
.status_zakaza{
    
    justify-content: normal;
}
}
</style>